import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; // Import Swiper's navigation CSS file
import { Autoplay, Pagination, Navigation } from 'swiper/modules'; // Import SwiperCore and Navigation module from Swiper's core
import { baseImageUrl, fetchStoreAPI } from '../api/api';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


 // Initialize SwiperCore with the Navigation module

const SliderPage = () => {
  const [store, setStore] = useState([]);
  
  const [activeCategory, setActiveCategory] = useState(null);


  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const data = await fetchStoreAPI();
        setStore(data);
      } catch (error) {
        // Handle the error if needed
      }
    };

    fetchStoreData();
  }, []);
 
  
  const navigateToStore= (storeId) => {
    localStorage.setItem('selectedStoreId', storeId);

    setActiveCategory(storeId);
  };

  
  
  

  
  return (
    <div className='container flex justify-center mx-auto py-5 md:px-8 w-full'>
      
      <Swiper
        spaceBetween={10}
        // centeredSlides={true}
        
        
        breakpoints={{
            // Mobile (less than 640px width)
            640: {
              slidesPerView: 1,
            },
            // Tablet (greater than 640px but less than 768px width)
        768: {
            slidesPerView: 3,
          },
          // Desktop (greater than 1024px width)
          1024: {
            
            slidesPerView: 4,
          },
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
       
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
         {store.map((item) => (
          <SwiperSlide key={item.id} onClick={() => navigateToStore(item.id)} >
        
            
            <Link to='/Store'>
            <div className='box py-10 mx-auto flex flex-col items-center'>
              <div className='img_box h-40 w-40 rounded-full bg-sky-300 overflow-hidden cursor-pointer'>
                <img
                  src={`${baseImageUrl}${item.logo}`}
                  alt={item.name}
                  className='h-16 w-16 mx-auto my-10 transition duration-500 transform scale-125 hover:scale-150'
                  onError={(e) => {
                    e.target.style.display = 'none'; 
                  }}
                />
              </div>
              <div className='detail text-center mt-3 '>
                <p className='text-gray-500'>{item.name}</p>
              </div>
            </div>
            </Link>
             
             
            
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderPage;
