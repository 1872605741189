const productDetail = [
    {
        id: 1,
        Title: "Galaxy Tab S6 Lite 10.4-inch Android Tablet 128GB.",
        Cat: 'Tablet',
        Price: '723',
        Img: '../img/tp1.jpg',
        Rating: 4.5
    },
    {
        id: 2,
        Title: "Waterproof Smartwatch.",
        Cat: 'Smart Watch',
        Price: '168',
        Img: '../img/tp2.jpg',
        Rating: 4.0
    },
    {
        id: 3,
        Title: "Cancelling Headphones Wireless.",
        Cat: 'Headphone',
        Price: '49',
        Img: '../img/tp3.jpg',
        Rating: 3.8
    },
    {
        id: 4,
        Title: "Professional Camera 4K Digital Video Camera.",
        Cat: 'Camera',
        Price: '1049',
        Img: '../img/tp4.jpg',
        Rating: 4.2
    },
    {
        id: 5,
        Title: "Mini Portable PD 22.5W Fast Charging Powerbank.",
        Cat: 'Powerbank',
        Price: '49',
        Img: '../img/tp5.jpg',
        Rating: 4.7
    },
    {
        id: 6,
        Title: "CPU Cooler 2 Heat Pipes 12mm 4 Pin PWM RGB.",
        Cat: 'Electronics',
        Price: '156',
        SalePrice: '75',
        Img: '../img/tp6.png',
        Rating: 3.5
    },
    {
        id: 7,
        Title: "Playstation 4 2TB Slim Gaming Console.",
        Cat: 'Gaming',
        Price: '2098',
        SalePrice: '1975',
        Img: '../img/tp7.jpg',
        Rating: 4.8
    },
    {
        id: 8,
        Title: "Mini Portable Mobile Phone Powerbank for iPhone.",
        Cat: 'Electronics',
        Price: '386',
        SalePrice: '275',
        Img: '../img/tp8.jpg',
        Rating: 4.1
    },
    {
        id: 9,
        Title: "Sale Product",
        Cat: 'Electronics',
        Price: '100',
        SalePrice: '75',
        Img: '../img/p11.jpg',
        Rating: 4.5,
    }

];

export default productDetail;
