const productDetail = [
    {
        id: 1,
        Title: "Galaxy Tab S6 Lite 10.4-inch Android Tablet 128GB.",
        Cat: 'Men',
        Store: "Amazons",
        Price: '723',
        Img: '../img/tp1.jpg',
        Rating: 4.5,
        deliveryTime: "2- 3 working Days",
        Brand: "Samsung",
        Origin: "China",
        Model: 2016,
        Description: "Experience the power and versatility of the Samsung Galaxy Tab S6 Lite. This 10.4-inch Android tablet comes with 128GB of storage, perfect for all your apps and media. Its vibrant display and S Pen support make it a great choice for productivity and entertainment. With a long-lasting battery and fast performance, it's the tablet you've been waiting for.",
        Reviews: [
            {
                id: 1,
                username: "JohnDoe",
                Rating: 2,
                comment: "Excellent tablet! The S Pen is a game-changer."
            },
            {
                id: 2,
                username: "JaneSmith",
                Rating: 4,
                comment: "Good value for the price. The display is sharp."
            },

        ],
        Specifications: {
            Display: "10.4 inches, TFT LCD",
            Processor: "Exynos 9610 Octa-core",
            RAM: "4GB",
            Storage: "128GB (expandable)",
            OperatingSystem: "Android 10 with One UI 2.0",
            Battery: "7040mAh",
            Camera: "8MP rear, 5MP front",
            Connectivity: "Wi-Fi, Bluetooth 5.0, USB-C",
            Dimensions: "244.5 x 154.3 x 7 mm",
            Weight: "467g"
        }
    },
    {
        id: 2,
        Title: "Waterproof Smartwatch.",
        Cat: 'men',
        Store: "Daraz",
        Price: '168',
        Img: '../img/tp2.jpg',
        Rating: 4.0,
        deliveryTime: "2-3 working Days",
        Brand: "SmartWatch Co.",
        Origin: "China",
        Model: 2022,
        Description: "Stay connected with the Waterproof Smartwatch. This stylish and functional smartwatch is perfect for tracking your fitness goals and receiving notifications from your smartphone. With a waterproof design, you can wear it in any weather. It also features a long-lasting battery and a user-friendly interface.",
        Reviews: [
            {
                id: 1,
                username: "SmartWatchFan",
                Rating: 4,
                comment: "Great value for the features it offers."
            },
            {
                id: 2,
                username: "FitnessEnthusiast",
                Rating: 4.5,
                comment: "Excellent fitness tracking capabilities."
            },
            // Add more reviews here
        ],
        Specifications: {
            Display: "1.3-inch AMOLED",
            Processor: "Quad-core",
            RAM: "512MB",
            Storage: "8GB",
            OperatingSystem: "Custom OS",
            Battery: "300mAh",
            Camera: "No",
            Connectivity: "Bluetooth, Wi-Fi",
            Dimensions: "42mm x 42mm x 12mm",
            Weight: "60g"
        }
    },
    {
        id: 3,
        Title: "Cancelling Headphones Wireless.",
        Cat: 'motherchild',
        Store: "Levis",
        Price: '49',
        Img: '../img/tp3.jpg',
        Rating: 3.8,
        deliveryTime: "2-4 working Days",
        Brand: "SoundMaster",
        Origin: "USA",
        Model: 2022,
        Description: "Enjoy your music without distractions with Cancelling Headphones Wireless. These wireless headphones provide high-quality audio and effective noise cancellation. They are comfortable to wear for extended periods and have a long-lasting battery life.",
        Reviews: [
            {
                id: 1,
                username: "MusicLover123",
                Rating: 4,
                comment: "Great sound quality and noise cancellation."
            },
            {
                id: 2,
                username: "Audiophile22",
                Rating: 3.5,
                comment: "Decent headphones for the price."
            },
            // Add more reviews here
        ],
        Specifications: {
            Type: "Over-ear",
            Connectivity: "Wireless (Bluetooth)",
            Battery: "Up to 20 hours",
            Weight: "275g",
            Color: "Black",
            Foldable: "Yes",
            Microphone: "Built-in",
            Controls: "Touch-sensitive",
        }
    },
    {
        id: 4,
        Title: "Professional Camera 4K Digital Video Camera.",
        Cat: 'Casual',
        Store: "Abbasi",
        Price: '1049',
        Img: '../img/tp4.jpg',
        Rating: 4.2,
        deliveryTime: "3-5 working Days",
        Brand: "ProCam",
        Origin: "Japan",
        Model: 2022,
        Description: "Capture stunning 4K videos with the Professional Camera 4K Digital Video Camera. This camera features advanced optics and image stabilization for professional-quality footage. It's perfect for content creators and videographers.",
        Reviews: [
            {
                id: 1,
                username: "VideoPro123",
                Rating: 4.5,
                comment: "Impressive video quality and features."
            },
            {
                id: 2,
                username: "Photographer22",
                Rating: 4.0,
                comment: "Great camera for the price."
            },
            // Add more reviews here
        ],
        Specifications: {
            Sensor: "1-inch CMOS",
            Resolution: "4K UHD",
            Lens: "24-70mm f/2.8-4.5",
            ImageStabilization: "Yes",
            Battery: "2800mAh",
            Connectivity: "Wi-Fi, Bluetooth, USB-C",
            Dimensions: "150 x 110 x 80 mm",
            Weight: "850g"
        }
    },
    {
        id: 5,
        Title: "Mini Portable PD 22.5W Fast Charging Powerbank.",
        Cat: 'shoes',
        Store: "Outfitter",
        Price: '49',
        Img: '../img/tp5.jpg',
        Rating: 4.7,
        deliveryTime: "2-3 working Days",
        Brand: "PowerX",
        Origin: "USA",
        Model: 2022,
        Description: "Stay powered up on the go with the Mini Portable PD 22.5W Fast Charging Powerbank. This compact and lightweight powerbank can quickly charge your devices, including smartphones and tablets. It's a must-have for travelers and commuters.",
        Reviews: [
            {
                id: 1,
                username: "TechGuru99",
                Rating: 5,
                comment: "Fast charging and compact design."
            },
            {
                id: 2,
                username: "Traveler123",
                Rating: 4.5,
                comment: "Reliable power source for travel."
            },
            // Add more reviews here
        ],
        Specifications: {
            Capacity: "10000mAh",
            Output: "22.5W PD",
            Input: "18W PD",
            Ports: "2 USB-A, 1 USB-C",
            Weight: "200g",
            Color: "Black"
        }
    },
    {
        id: 6,
        Title: "CPU Cooler 2 Heat Pipes 12mm 4 Pin PWM RGB.",
        Cat: 'Electronics',
        Store: "Amazons",
        Price: '156',
        Img: '../img/tp6.png',
        Rating: 3.5,
        deliveryTime: "2-3 working Days",
        Brand: "CoolTech",
        Origin: "Taiwan",
        Model: 2022,
        Description: "Keep your CPU cool and enhance performance with the CPU Cooler 2 Heat Pipes 12mm 4 Pin PWM RGB. This CPU cooler features advanced heat pipes and RGB lighting for both functionality and aesthetics. It's compatible with a wide range of CPU sockets.",
        Reviews: [
            {
                id: 1,
                username: "PCEnthusiast",
                Rating: 4,
                comment: "Effective cooling and looks great in my build."
            },
            {
                id: 2,
                username: "Gamer22",
                Rating: 3.0,
                comment: "Decent cooler, but a bit noisy."
            },
            // Add more reviews here
        ],
        Specifications: {
            Compatibility: "Intel LGA 115X/1200, AMD AM4/AM3+/AM3",
            FanSize: "120mm",
            NoiseLevel: "18-30 dBA",
            RGB: "Yes",
            HeatPipes: "2",
            Weight: "650g"
        }
    },
    {
        id: 7,
        Title: "Playstation 4 2TB Slim Gaming Console.",
        Cat: 'cosmetics',
        Store: "Levis",
        Price: '2098',
        Img: '../img/tp7.jpg',
        Rating: 4.8,
        deliveryTime: "3-5 working Days",
        Brand: "Sony",
        Origin: "Japan",
        Model: 2022,
        Description: "Experience immersive gaming with the Playstation 4 2TB Slim Gaming Console. This gaming console offers a vast library of games and supports HDR graphics for stunning visuals. It's perfect for gamers of all levels.",
        Reviews: [
            {
                id: 1,
                username: "GamerPro123",
                Rating: 5,
                comment: "Awesome gaming console, great graphics."
            },
            {
                id: 2,
                username: "CasualGamer22",
                Rating: 4.5,
                comment: "Lots of games to choose from, highly recommended."
            },
            // Add more reviews here
        ],
        Specifications: {
            Storage: "2TB HDD",
            Resolution: "Full HD 1080p",
            HDR: "Yes",
            Controller: "DualShock 4",
            Connectivity: "Wi-Fi, Ethernet, USB",
            Dimensions: "288 x 265 x 39 mm",
            Weight: "2.1kg"
        }
    },
    {
        id: 8,
        Title: "Mini Portable Mobile Phone Powerbank for iPhone. ",
        Cat: 'Men',
        Store: "Amazons",
        Price: '386',
        Img: '../img/tp8.jpg',
        Rating: 4.1,
        deliveryTime: "2-3 working Days",
        Brand: "iPower",
        Origin: "USA",
        Model: 2022,
        Description: "Stay charged on the go with the Mini Portable Mobile Phone Powerbank for iPhone. This powerbank is designed specifically for iPhones and offers fast charging capabilities. It's compact and easy to carry in your pocket or bag.",
        Reviews: [
            {
                id: 1,
                username: "iPhoneUser123",
                Rating: 4,
                comment: "Great powerbank for my iPhone, charges quickly."
            },
            {
                id: 2,
                username: "Traveler22",
                Rating: 4.5,
                comment: "Reliable power source during travel."
            },
            // Add more reviews here
        ],
        Specifications: {
            Capacity: "5000mAh",
            Output: "18W PD",
            Input: "18W PD",
            Compatibility: "iPhone 11 and newer",
            Weight: "150g",
            Color: "White"
        }
    },
    {
        id: 9,
        Title: "Microsoft Surface Pro 8-13' Touchscreen.",
        Cat: 'Men',
        Store: "Levis",
        Price: '693',
        Img: '../img/p9.jpg',
        Rating: 4.6,
        deliveryTime: "2- 3 working Days",
        Brand: "Microsoft",
        Origin: "USA",
        Model: 2022,
        Description: "Experience the versatility of the Microsoft Surface Pro 8. This 13-inch tablet features a touchscreen display and powerful performance. It's perfect for work or entertainment on the go.",
        Reviews: [
            {
                id: 1,
                username: "SurfaceProFan",
                Rating: 5,
                comment: "Fantastic tablet with great performance."
            },
            {
                id: 2,
                username: "ProductivityGuru",
                Rating: 4.5,
                comment: "Ideal for productivity tasks and entertainment."
            },
            // Add more reviews here
        ],
        Specifications: {
            Display: "13-inch Touchscreen",
            Processor: "Intel Core i5",
            RAM: "8GB",
            Storage: "256GB SSD",
            OperatingSystem: "Windows 11",
            Battery: "Up to 10 hours",
            Connectivity: "Wi-Fi, Bluetooth, USB-C",
            Dimensions: "287 x 208 x 9 mm",
            Weight: "790g"
        }
    },
    {
        id: 10,
        Title: "Playstation 4 2TB Slim Gaming Console.",
        Cat: 'Women',
        Store: "Outfitter",
        Price: '5036',
        Img: '../img/p10.jpg',
        Rating: 4.9,
        deliveryTime: "3-5 working Days",
        Brand: "Sony",
        Origin: "Japan",
        Model: 2022,
        Description: "Experience immersive gaming with the Playstation 4 2TB Slim Gaming Console. This gaming console offers a vast library of games and supports HDR graphics for stunning visuals. It's perfect for gamers of all levels.",
        Reviews: [
            {
                id: 1,
                username: "GamerPro123",
                Rating: 5,
                comment: "Awesome gaming console, great graphics."
            },
            {
                id: 2,
                username: "CasualGamer22",
                Rating: 4.5,
                comment: "Lots of games to choose from, highly recommended."
            },
            // Add more reviews here
        ],
        Specifications: {
            Storage: "2TB HDD",
            Resolution: "Full HD 1080p",
            HDR: "Yes",
            Controller: "DualShock 4",
            Connectivity: "Wi-Fi, Ethernet, USB",
            Dimensions: "288 x 265 x 39 mm",
            Weight: "2.1kg"
        }
    },
    {
        id: 11,
        Title: "Echo Show 5 (2nd Gen) Adjustable Stand | Charcoal",
        Cat: 'Men',
        Store: "Daraz",
        Price: '198',
        Img: '../img/p11.jpg',
        Rating: 4.4,
        deliveryTime: "2-3 working Days",
        Brand: "Amazons",
        Origin: "USA",
        Model: 2022,
        Description: "Stay connected with the Echo Show 5 (2nd Gen). This smart display with an adjustable stand allows you to make video calls, stream content, and control your smart home devices with ease. It's the perfect addition to your home.",
        Reviews: [
            {
                id: 1,
                username: "SmartHomeEnthusiast",
                Rating: 4,
                comment: "Great addition to my smart home setup."
            },
            {
                id: 2,
                username: "TechSavvy22",
                Rating: 4.5,
                comment: "Useful for video calls and home automation."
            },
            // Add more reviews here
        ],
        Specifications: {
            Display: "5.5-inch Touchscreen",
            Processor: "Quad-core",
            RAM: "2GB",
            Storage: "8GB",
            OperatingSystem: "Amazons Alexa",
            Camera: "2MP",
            Connectivity: "Wi-Fi, Bluetooth",
            Dimensions: "148 x 86 x 73 mm",
            Weight: "410g"
        }
    },
    {
        id: 12,
        Title: "Echo Dot smart speaker",
        Cat: 'Women',
        Store: "Abbasi",
        Price: '793',
        Img: '../img/p12.jpg',
        Rating: 4.3,
        deliveryTime: "2-3 working Days",
        Brand: "Amazons",
        Origin: "USA",
        Model: 2022,
        Description: "Experience the convenience of voice-controlled entertainment and smart home control with the Echo Dot smart speaker. This compact and powerful smart speaker can play music, answer questions, and control compatible smart devices.",
        Reviews: [
            {
                id: 1,
                username: "SmartHomeUser123",
                Rating: 4,
                comment: "Great device for starting a smart home setup."
            },
            {
                id: 2,
                username: "MusicLover22",
                Rating: 4.5,
                comment: "Excellent sound quality for its size."
            },
            // Add more reviews here
        ],
        Specifications: {
            Speaker: "1.6-inch speaker",
            Connectivity: "Wi-Fi, Bluetooth",
            VoiceAssistant: "Amazons Alexa",
            Dimensions: "100 x 100 x 89 mm",
            Weight: "300g"
        }
    },
        {
            id: 13,
            Title: "Echo Dot smart speaker",
            Cat: 'Men',
            Store: "Daraz",
            Price: '793',
            Img: '../img/p12.jpg',
            Rating: 4.3,
            deliveryTime: "2-3 working Days",
            Brand: "Amazons",
            Origin: "USA",
            Model: 2022,
            Description: "Experience the convenience of voice-controlled entertainment and smart home control with the Echo Dot smart speaker. This compact and powerful smart speaker can play music, answer questions, and control compatible smart devices.",
            Reviews: [
                {
                    id: 1,
                    username: "SmartHomeUser123",
                    Rating: 4,
                    comment: "Great device for starting a smart home setup."
                },
                {
                    id: 2,
                    username: "MusicLover22",
                    Rating: 4.5,
                    comment: "Excellent sound quality for its size."
                },
                // Add more reviews here
            ],
            Specifications: {
                Speaker: "1.6-inch speaker",
                Connectivity: "Wi-Fi, Bluetooth",
                VoiceAssistant: "Amazons Alexa",
                Dimensions: "100 x 100 x 89 mm",
                Weight: "300g"
            }
    },
    {
        id: 14,
        Title: " smart speaker",
        Cat: 'Men',
        Store: "Amazons",
        Price: '793',
        Img: '../img/p12.jpg',
        Rating: 4.3,
        deliveryTime: "2-3 working Days",
        Brand: "Amazons",
        Origin: "USA",
        Model: 2022,
        Description: "Experience the convenience of voice-controlled entertainment and smart home control with the Echo Dot smart speaker. This compact and powerful smart speaker can play music, answer questions, and control compatible smart devices.",
        Reviews: [
            {
                id: 1,
                username: "SmartHomeUser123",
                Rating: 4,
                comment: "Great device for starting a smart home setup."
            },
            {
                id: 2,
                username: "MusicLover22",
                Rating: 4.5,
                comment: "Excellent sound quality for its size."
            },
            // Add more reviews here
        ],
        Specifications: {
            Speaker: "1.6-inch speaker",
            Connectivity: "Wi-Fi, Bluetooth",
            VoiceAssistant: "Amazons Alexa",
            Dimensions: "100 x 100 x 89 mm",
            Weight: "300g"
        }
},
{
    id: 15,
    Title: " smart speaker",
    Cat: 'Bags',
    Store: "Amazons",
    Price: '793',
    Img: '../img/p12.jpg',
    Rating: 4.3,
    deliveryTime: "2-3 working Days",
    Brand: "Amazons",
    Origin: "USA",
    Model: 2022,
    Description: "Experience the convenience of voice-controlled entertainment and smart home control with the Echo Dot smart speaker. This compact and powerful smart speaker can play music, answer questions, and control compatible smart devices.",
    Reviews: [
        {
            id: 1,
            username: "SmartHomeUser123",
            Rating: 4,
            comment: "Great device for starting a smart home setup."
        },
        {
            id: 2,
            username: "MusicLover22",
            Rating: 4.5,
            comment: "Excellent sound quality for its size."
        },
        // Add more reviews here
    ],
    Specifications: {
        Speaker: "1.6-inch speaker",
        Connectivity: "Wi-Fi, Bluetooth",
        VoiceAssistant: "Amazons Alexa",
        Dimensions: "100 x 100 x 89 mm",
        Weight: "300g"
    }
},
{
    id: 16,
    Title: "Playstation 4 2TB Slim Gaming Console.",
    Cat: 'Men',
    Store: "Amazons",
    Price: '2098',
    Img: '../img/tp7.jpg',
    Rating: 4.8,
    deliveryTime: "3-5 working Days",
    Brand: "Sony",
    Origin: "Japan",
    Model: 2022,
    Description: "Experience immersive gaming with the Playstation 4 2TB Slim Gaming Console. This gaming console offers a vast library of games and supports HDR graphics for stunning visuals. It's perfect for gamers of all levels.",
    Reviews: [
        {
            id: 1,
            username: "GamerPro123",
            Rating: 5,
            comment: "Awesome gaming console, great graphics."
        },
        {
            id: 2,
            username: "CasualGamer22",
            Rating: 4.5,
            comment: "Lots of games to choose from, highly recommended."
        },
        // Add more reviews here
    ],
    Specifications: {
        Storage: "2TB HDD",
        Resolution: "Full HD 1080p",
        HDR: "Yes",
        Controller: "DualShock 4",
        Connectivity: "Wi-Fi, Ethernet, USB",
        Dimensions: "288 x 265 x 39 mm",
        Weight: "2.1kg"
    }
},
];

export default productDetail;
