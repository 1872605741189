// Faq.js

import React from 'react';

const Faq = () => {
  return (
    <div className="p-4">
      <h2 className="font-bold text-2xl mb-4 text-center">Frequently Asked Questions</h2>

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4">1. What is Invofy Store?</h3>
        <p>Invofy Store is an online platform where you can [brief description of the purpose of the platform].</p>
      

      
        <h3 className="font-bold text-lg mb-4">2. How do I create an account?</h3>
        <p>To create an account, click on the "Sign Up" button and follow the instructions provided. You will need to provide [required information].</p>
      

      
        <h3 className="font-bold text-lg mb-4">3. How can I place an order?</h3>
        <p>To place an order, browse through the available products, add items to your cart, and proceed to checkout. Follow the steps to complete your purchase.</p>
      

      {/* ... Additional sections for common questions */}

      
        <h3 className="font-bold text-lg mb-4">8. How do I contact customer support?</h3>
        <p>You can contact our customer support team by [email/phone/support ticket]. We are here to assist you with any questions or concerns you may have.</p>
      </section>
    </div>
  );
};

export default Faq;
