
import { AiOutlineYoutube } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import PrivacyPolicy from "./help/PrivacyPolicy"


import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  
} from "react-icons/fa";

import 'aos/dist/aos.css';
import { Link } from "react-router-dom";








const Footer = () => {
  
  return (
    <div  className="text-black bg-sky-100">
      <div className="container">
        <div  className="grid md:grid-cols-3 pb-30 pt-5">
          {/* company details */}
          <div className="py-8 px-4">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              <img  src='./img/logo1.png'alt="" style={{ width: '160px', height:'45px' }} />
              
            </h1>
            <p >
            Invofy Shop is more than a store; it's a community of like-minded individuals who appreciate quality and style.
            </p>
          </div>

          {/* Footer Links */}
          <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10">
            <div>
              <div className="py-8 px-4">
                <h1  className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Pages
                </h1>
                <ul className="flex flex-col gap-3">
                <li>
                                <Link to='/' className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>Home</Link>
                            </li>
                            <li>
                                <Link to='/about' className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>About Us </Link>
                            </li>
                            <li>
                                <Link to='/Contact' className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>Contact Us</Link>
                            </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="py-8 px-4">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Help
                </h1>
                <ul className="flex flex-col gap-3">
                <li>
                                <Link to="/terms&condtions" className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to='/FAQs' className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>FAQ's</Link>
                            </li>
                            <li>
                                <Link to='/PrivacyPolicy' className='text-neutral-600 text-sm font-normal leading-tight hover:text-sky-600 transition duration-500 cursor-pointer'>Privacy Policy</Link>
                            </li>
                </ul>
              </div>
            </div>

            {/* social links */}
            <div>
              <div className="flex items-center gap-3 mt-6">
              <a href='https://instagram.com' target='_blank' rel='noopener noreferrer'>
                                    <FaInstagram className=' text-5xl hover:bg-sky-500 hover:text-white rounded-full p-1 cursor-pointer transition duration-300 ease-out hover:ease-in' />
                                </a>
                <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
                                    < FaFacebook className=' text-5xl hover:bg-sky-500 hover:text-white rounded-full p-1 cursor-pointer transition duration-300 ease-out hover:ease-in' />
                                </a>
                                <a href='https://twitter.com/home' target='_blank' rel='noopener noreferrer'>
                                <FiTwitter className='text-5xl hover:bg-sky-500 hover:text-white rounded-full p-1 cursor-pointer transition duration-300 ease-out hover:ease-in' />
                                </a>
                                
                                <a href='https://youtube.com' target='_blank' rel='noopener noreferrer'>
                                <AiOutlineYoutube className=' text-5xl hover:bg-sky-500 hover:text-white rounded-full p-1 cursor-pointertransition duration-300 ease-out hover:ease-in' />
                                </a>
              </div>
              <div className="mt-6">
                {/* <div className="flex items-center gap-3">
                  <FaLocationArrow />
                  <p>Islamabad,Pakistan</p>
                </div> */}
                {/* <div className="flex items-center gap-3 mt-3">
                  <FaMobileAlt />
                  <p>+91 3145679889</p>
                </div> */}
              </div>
            </div>
          </div>
         
        </div>
         <div className="text-center pt-2 text-sm text-neutral-600">
          © {new Date().getFullYear()} Invofy Shop. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
