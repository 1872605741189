/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs';
import { FiHeart, FiTruck } from 'react-icons/fi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { CiPercent } from 'react-icons/ci';
import { BiHeadphone } from 'react-icons/bi';
import { CiLocationArrow1 } from "react-icons/ci";
import { AiOutlineShoppingCart, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';
import { AiOutlineHeart } from 'react-icons/ai';
import homeProduct from './homeProduct';
import Slider from '../Components/Slider';
import StarRating from '../Components/StarRating';
import { useState } from 'react';

import { baseImageUrl, fetchCardsAPI, fetchHomeProducts, fetchProducts  } from '../api/api';
import SliderPage from '../Components/Slider1';
import { FadeLoader } from 'react-spinners';



const Home = ({ detail, view, close, setClose, addtowishlist, addtocart,color }) => {
    
    const [topProducts, setTopProducts] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState([]);
const [visibleProducts, setVisibleProducts] = useState(2);
const [visibleTopProducts, setVisibleTopProducts] = useState(3);
const  [toggleHeart, setToggleHeart] = useState(false)


const changeColor = (id) => {
    setToggleHeart((prevState) => ({
        ...prevState,
        [id]: !prevState[id] || false // If prevState[id] is undefined (first time), set it to false
    }));
};

   
     
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); // State for loader
    useEffect(()=>{
        setLoading(false)
        setTimeout(()=>{
            setLoading(false)
        },5000)
    },[])

   





useEffect(() => {
   
    const fetchTopProducts = async () => {
        try {
            // Fetch top products
            console.log("Loading top products index", visibleTopProducts);
            const response = await fetchHomeProducts({ is_top: "1", initial: 0, final: visibleTopProducts }); // Pass the condition to fetch only top products
            setTopProducts(response); // Update state with the fetched top products
            console.log('Top products fetched:', response); // Log the fetched top products
            
            console.log('Top products fetched:', topProducts);
        } catch (error) {
            console.error('Error fetching top products:', error);
        }
    };

    fetchTopProducts();
   
    }, []);

    const loadMoreTopProducts = async () => {
        try {
            setLoading(true); // Start the loader
            const newProducts = await fetchHomeProducts({ is_top: "1", initial: visibleTopProducts, final: visibleTopProducts + 4 });
            setTopProducts((prevTopProducts) => {
                const uniqueNewProducts = newProducts.filter(newProduct => !prevTopProducts.some(prevProduct => prevProduct.id === newProduct.id));
                return [...prevTopProducts, ...uniqueNewProducts];
            });
            setVisibleTopProducts((prevVisibleTopProducts) => prevVisibleTopProducts + newProducts.length);
        } catch (error) {
            console.error('Error fetching more top products:', error);
        } finally {
            setLoading(false); // Stop the loader
        }
    };
    




useEffect(() => {

    const fetchFeaturedProducts = async () => {
        try {
            // Fetch featured products
            const response = await  fetchHomeProducts({ is_featured: "1", initial: 0, final: 1 }); // Pass the condition to fetch only featured products
            setFeaturedProducts(response); // Update state with the fetched featured products
            console.log('Featured products fetched:', response); // Log the fetched featured products
        } catch (error) {
            console.error('Error fetching featured products:', error);
        }
    };

    fetchFeaturedProducts();

    }, []);


    const loadMoreFeaturedProducts = async () => {
        try {
            setLoading(true); // Start the loader
            console.log("Loading more featured products", visibleProducts);
            const newProducts = await fetchHomeProducts({ is_featured: "1", initial: visibleProducts, final: visibleProducts + 1 });
            setFeaturedProducts((prevFeaturedProducts) => [...prevFeaturedProducts, ...newProducts]);
            setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 1);
        } catch (error) {
            console.error('Error fetching more featured products:', error);
        } finally {
            setLoading(false); // Stop the loader
        }
    };
    
    






    



   

    const [cards, setCards] = useState([]);
    useEffect(() => {
    const fetchCardsData = async () => {
        try {
        const data = await fetchCardsAPI ();
        setCards(data);
        } catch (error) {
        // Handle the error if needed
        }
    };

    fetchCardsData(); 
    }, []);

    console.log("cards from API",cards);
    
    return (
        // <>
        // <div className='loader' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',zIndex: 9999  }}>
        // <PacmanLoader color="blue" loading={loading}  size={50} aria-label="Loading Spinner" />
        //     </div>
        //     {!loading && (
                <>
            {
                close ?
                    <div className='product_detail'>
                        <div className='container'>
                            <button onClick={() => setClose(false)} className='closebtn'><AiOutlineCloseCircle /></button>
                            {
                                detail.map((object) => {
                                    return (
                                        <div className='productbox'>
                                            <div className='img-box'>
                                                <img src={object.Img} alt={object.Title}></img>
                                            </div>
                                            <div className='detail'>
                                                <h4>{object.Cat}</h4>
                                                <h2>{object.Title}</h2>
                                                <StarRating rating={object.Rating} />
                                                <p>A Screen Everyone Will Love: Whether your family is streaming or video chatting with friends tablet A8... </p>
                                                <h3>${object.Price}</h3>
                                                <button>Buy Now</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className='productbox'></div>
                        </div>
                    </div> : null
            }






            
            <Slider />
            <SliderPage />
        


 
            <div className=" container category-section-wrapper w-full transition-all duration-500 ease-in-out px-5">
  <div className="container mx-auto md:py-[60px] py-[30px]">
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px] lg:mb-[60px] mb-[30px]">
      {cards.map((crd) => (
      <div className="item w-full rounded-2xl overflow-hidden">
        <div
          className="w-full h-[453px] rounded bg-center bg-black text-white"
          style={{
            backgroundImage:`url(${baseImageUrl}${crd.card})`, // Replace with static image path
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="px-[40px] pt-[40px]">
            <span className="text-sm text-qblack mb-2 inline-block uppercase font-medium">
              {crd.card_title}
            </span>
            <h1 className="text-[34px] leading-[38px] overflow-hidden overflow-ellipsis line-clamp-2 font-semibold text-qblack mb-[20px] w-[277px]">
              {crd.card_sub_title}
            </h1>
            <a href={crd.card_url} target='blank' >
              <div className="w-[128px] h-[40px] bg-qyellow">
              <Link
                 to={crd.card_url}
                    target="_blank"
                 className="px-4 py-3 w-[9rem] text-blue-1300 bg-sky-400 rounded-full hover:bg-blue-600 hover:text-white transition duration-500 flex items-center"
                    >
  <span className="text-base">Shop Now</span>
  <CiLocationArrow1 className="text-lg mx-2 my-auto" />
</Link>

              </div>
            </a>
          </div>
        </div>
      </div>))}
      {/* <div className="item w-full rounded-2xl overflow-hidden">
       
      </div> */}
    </div>
  </div>
</div>


            <div className="product_sale container mx-auto">
                <h2 className="text-2xl font-bold ml-8 mb-8">Flash Sales</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                   
                {featuredProducts.map((object) => (

                        <div className="box group duration-500 border-2 hover:border-blue-500   bg-white border rounded-[1rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={object.id}>
                            <div className="img_box h-[50%] relative">
                                <img
                                  src={`${baseImageUrl}${object.thumb_image}`}

                                    alt={object.name}
                                    className="w-full h-60 object-contain cursor-pointer"
                                    onClick={() => navigate(`/buy/${object.id}`)}
                                />
                                <div className=" icon duration-500 list-none group-hover:translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                    
                                    <li
                                        onClick={() => view(object)}
                                        className="bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer transition hover:bg-blue-700"
                                    >
                                        <BsEye className='w-6 h-6' />
                                    </li>
                                    
                                </div>
                            </div>
                            <div className="detail h-[38%] p-1 overflow-hidden">
                               <div className='flex justify-between'>
                                 <p 
                                 onClick={() => navigate(`/buy/${object.id}`)}
                                 className="text-lg py-1 font-bold overflow-hidden overflow-ellipsis line-clamp-2 text-gray-800 cursor-pointer hover:underline">{object.name}</p>
                                 
                                 <li onClick={() => addtowishlist(object)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
                     <div
                 className="w-6 h-6 flex items-center justify-center rounded-full overflow-hidden"
                  onClick={() => changeColor(object.id)}
        
             >
        <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 21.638l-1.428-1.286C5.169 16.088 2 12.35 2 8.5 2 5.408 4.308 3 7.5 3c1.902 0 3.616 1.047 4.5 2.714C12.884 4.047 14.598 3 16.5 3 19.692 3 22 5.408 22 8.5c0 3.85-3.169 7.588-8.572 12.852L12 21.638z"
                fill={toggleHeart[object.id] ? 'red' : 'none'}
            />
        </svg>
    </div>
</li>
                                </div>
                                <h3
                                    className="text-gray-500 overflow-hidden overflow-ellipsis  line-clamp-2"
                                    
                                >
                                    {object.short_description}
                                </h3>
                                <div className='ml-5 mt-2 mb-2 h-[10%]'>

                                    <StarRating rating={object.Rating} />

                                    <h4 className="text-red-600 font-bold ml">${object.offer_price}</h4>
                                <del className="text-black ">${object.price}</del>

                                </div>
                                {/* <h4 className="text-red-600 font-bold ml">${object.SalePrice}</h4> */}
                                {/* <del className="text-black">${object.Price}</del> */}
                             
                            </div>

                            <div className='w-[60%] my-2 mx-auto  flex flex-center z-[10]'> 
                            <a href= {object.url} target="_blank" class="block w-[100%] bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-4 mx-auto rounded-full text-center" role="button">Buy Now</a>



                                   
                            </div>
   
                        </div>
                    ))}
                </div>
                 {/* {products.length > visibleItems && (   */}
                 
                 <div className=" bottom-25 py-2 md:py-7 flex justify-end pr-[3%] ">
    <button
        onClick={loadMoreFeaturedProducts}
        className="bg-blue-900 hover:bg-primary1 text-white font-bold py-2 px-4 rounded relative transition-all duration-300"
        style={{  overflow: 'hidden' }}
    >
        <span className= "px-2 ,">Show More
        {loading && (
            <FadeLoader
                color="rgba(54, 215, 183, 1)"
                height={4}
                margin={-10}
                radius={-2}
                width={4}
                // speedMultiplier={1}
                className=" absolute ml-6 py-1 "
                // style={{ zIndex: 1 }}
            />
        )}
        </span>
    </button>
</div>



              {/* )} */}
            </div>




            <div className="product ">
                <h2 className="text-2xl font-bold ml-8 mt-10 mb-6">Top Products</h2>
                <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6" id="itemGrid">
                {topProducts.map((object) => (
                        
                        <div className=" box group duration-500 hover:border-blue-500  bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={object.id}>
                            <div className="img_box h-[50%] relative">
                                <img
                                   src={`${baseImageUrl}${object.thumb_image}`}
                                    alt={object.name} 
                                    onClick={() => navigate(`/buy/${object.id}`)}
                                    className="w-full h-60 object-contain cursor-pointer"
                                />
                                <div className=" icon durattion-500 list-none group-hover:translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                   
                                    <li onClick={() => view(object)} className=" bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-500" bg-gray-500 list-none text-white px-2 py-1 rounded>
                                        <BsEye className="w-6 h-6" />
                                    </li>
                                    
                                </div>
                            </div>
                            
                            <div className="detail h-[35%] p-4">
                            <div className='flex justify-between'>
                                <p 
                                onClick={() => navigate(`/buy/${object.id}`)}
                                 className="text-lg py-2 font-bold text-gray-800 overflow-hidden overflow-ellipsis line-clamp-2 cursor-pointer hover:underline">{object.name}</p>
                                <li onClick={() => addtowishlist(object)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
                                <FiHeart
                    className={`w-6 h-6 ${toggleHeart[object.id] ? 'heart active' : 'heart'}`}
                    onClick={() => changeColor(object.id)}
                    style={{ color: toggleHeart[object.id] ? 'red' : 'currentColor' }}
                />

                                </li>
                                </div>
                                <h3
                                    
                                    className="text-sm text-gray-400 overflow-hidden overflow-ellipsis line-clamp-2"
                                >
                                    {object.short_description}
                                </h3>
                                <StarRating rating={object.Rating} />
                                <h4 className="text-xl py-2  font-semibold text-blue-500">${object.price}</h4>
                               
                            </div>
                            <div className='w-[60%] my-2 mx-auto flex flex-center z-[10]'>  
                            <a href={object.url} target="_blank" class="block w-[100%] bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-4 mx-auto rounded-full text-center" role="button">Buy Now</a>

                            </div>

                        </div>
                    ))}
                </div>
                {/* {products.length > visibleItems && (   */}
                <div className=" bottom-25 py-2 md:py-7 flex justify-end pr-[3%] ">
    <button
        onClick={loadMoreTopProducts} 
        className="bg-blue-900 hover:bg-primary1 text-white font-bold py-2 px-4 rounded relative transition-all duration-300"
        style={{  overflow: 'hidden' }}
    >
        <span className= "px-2 ,">Show More
        {loading && (
            <FadeLoader
                color="rgba(54, 215, 183, 1)"
                height={4}
                margin={-10}
                radius={-2}
                width={4}
                // speedMultiplier={1}
                className=" absolute ml-6 py-1 "
                // style={{ zIndex: 1 }}
            />
        )}
        </span>
    </button>
</div>

            
            </div>

            {/* <div className=' bg-blue-200 p-6'>
                <div className='container mx-auto flex justify-between'>
                    <div className="my-8 ml-8">
                        <h4 className='text-4xl text-blue-900 mb-6'>LATEST TECHNOLOGY ADDED</h4>
                        <h3 className='text-3xl text-blue-900 mb-6'>Apple iPad 10.2 9th Gen - 2021</h3>
                        <p className='text-xl text-blue-900 mb-6'>$ 986</p>
                        <Link
                                to="/product"
                                className="px-4 py-3 w-[9rem] text-blue-1300 bg-sky-400  rounded-full hover:bg-blue-600 hover:text-white transition duration-500 flex item-center"
                            >
                                <span className="text-base ">Shop Now</span>
                                <CiLocationArrow1  className=" text-lg mx-2 my-auto" />
            
                            </Link>
                    </div>
                    <div className='img_box'>
                        <img src='./img/slider-img.png' alt='sliderimg'></img>
                    </div>
                
                </div>
            
            </div> */}
           
        </>
    )

    
}

export default Home