/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';
import { AiOutlineHeart, AiOutlineCloseCircle } from 'react-icons/ai';
import { useAuth0 } from "@auth0/auth0-react";
import StarRating from '../Components/StarRating';
import productDetail from './productDetail';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FiHeart } from 'react-icons/fi';
import  { useEffect } from 'react';
import { BiCategoryAlt } from 'react-icons/bi';
import { fetchStoreAPI,fetchProducts,fetchStoreProducts, baseImageUrl   } from '../api/api';
import { FadeLoader } from 'react-spinners';

const Store = ({  detail, view, close, setClose, addtocart, addtowishlist }) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const [storesid, setstoresid] = useState(1)
    const [visibleProducts, setVisibleProducts] = useState(3);
    const [storeVisibleProducts, setstoreVisibleProducts] = useState(3);
    

    const navigate = useNavigate();

    const [store, setStore] = useState([]);
                useEffect(() => {
                const fetchStoreData = async () => {
                    try {
                    const data = await fetchStoreAPI();
                    setStore(data);
                    } catch (error) {
                    // Handle the error if needed
                    }
                };

                fetchStoreData(); 
                
               
                }, []);

                console.log("store from API",store);

                const [loading, setLoading] = useState(false); // State for loader
    useEffect(()=>{
        setLoading(false)
        setTimeout(()=>{
            setLoading(false)
        },5000)
    },[])

    useEffect(() => {
        const selectedStoreId = localStorage.getItem('selectedStoreId');
        if (selectedStoreId) {
          loadStoreProducts(selectedStoreId);
          
        }
      }, []);
      

                        const [products, setProducts] = useState([]);
                   

                        useEffect(() => {
                            const fetchData = async () => {
                              try {
                                console.log("store[0].id:", store[0].id)
                                const newProducts = await fetchStoreProducts (0 , visibleProducts, store[0].id ?? 1); 
                                setProducts(newProducts);
                                setfilteredProducts(newProducts);
                                setstoresid(store[0].id);
                              } catch (error) {
                                console.error('Error fetching products:', error);
                              }
                            };
                          
                            fetchData();
                          }, [store]); // Empty dependency array
                          
                          console.log("products from API", products);
                          
                        

                        const loadMoreProducts = async () => {
                            try { 
                                 setLoading(true); // Start the loader
                                console.log("visibleProducts on load more:", visibleProducts)
                              const newProducts = await fetchStoreProducts (visibleProducts + 1, visibleProducts + 4, storesid);
                              setProducts((prevProducts) => [...prevProducts, ...newProducts]);
                              setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
                            } catch (error) {
                              console.error('Error fetching more products:', error);
                            }finally {
                                setLoading(false); // Stop the loader
                            }
                          };

                          const loadStoreProducts = async (StoreId) => {
                            try {
                                console.log("products on store item click:", StoreId)
                                const newProducts = await fetchStoreProducts(0, visibleProducts, StoreId);
                                setProducts(newProducts);
                                setVisibleProducts(3); // Reset visibleProducts to initial value when loading products for a new store
                                setstoresid(StoreId);
                                
                            } catch (error) {
                                console.error('Error fetching more products:', error);
                            }
                        };
                        
    
                    //    Function to filter products based on the selected store 
                    
                    const [filteredProducts, setfilteredProducts] = useState([]); 


                    const filterProductByStore = async(selectedStore) => {
                       console.log(products)
                        const updatedProducts = await products.filter((productos) => productos.store_id == selectedStore);
                         await setfilteredProducts(updatedProducts);
                        console.log("Filtered Products:", updatedProducts); 
                      };
                        

    const showAllProducts = () => {
        setfilteredProducts(products);
    };
    console.log("filterproducts",filteredProducts)
    
    const  [toggleHeart, setToggleHeart] = useState(false)


    const changeColor = (id) => {
        setToggleHeart((prevState) => ({
            ...prevState,
            [id]: !prevState[id] || false // If prevState[id] is undefined (first time), set it to false
        }));
    };
  
    
 

    return (
        <>
            {close ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-25 bg-black">
                    <div className="container mx-auto bg-white rounded-lg p-8 shadow-lg relative mb-b">
                        <button
                            onClick={() => setClose(false)}
                            className="absolute top-0 right-0 mt-2 mr-2 text-2xl text-gray-600 cursor-pointer"
                        >
                            <AiOutlineCloseCircle />
                        </button>
                        {detail.map((object) => (
                            <div className="flex space-x-4" key={object.id}>
                                <div className="w-1/2">
                                    <img src={object.Img} alt={object.Title} className="w-64 h-64 object-cover" />
                                </div>
                                <div className="w-1/2">
                                    <h4 className="text-blue-900 text-lg">{object.Cat}</h4>
                                    <h2 className="text-2xl text-blue-900 font-semibold">{object.Title}</h2>
                                    <StarRating rating={object.Rating} />
                                    <p className="text-gray-600">
                                        A Screen Everyone Will Love: Whether your family is streaming or video chatting with friends tablet A8...
                                    </p>
                                    <h3 className="text-blue-900 text-xl font-semibold">${object.Price}</h3>
                                    <button onClick={() => addtowishlist(object)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                                       <div className='flex flex-row items-center justify-between'>
                                        
                                        <p>Add To wishlist </p><AiOutlineHeart className='ml-1'/>
                                        </div> 
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}




            <div className='products'>
                <div className="flex items-center justify-center mb-4">
                    <h2 className="text-2xl font-semibold text-center py-4">Stores</h2>
                    
                </div>
                <div className='container'>
                    <div className='filter'>
                        
                        <div className='categories'>
                        
                            <h3  className="mb-2 text-lg font-semibold flex items-center"> Store Categories <BiCategoryAlt size={20} className='ml-1 ' />
                            </h3>

                         <ul className="flex flex-wrap justify-center " > 

                         {store.map((stoora) => (
                         <>
                         
                            <li
        key={stoora.id}
        
        className={`cursor-pointer flex items-center mr-4 mb-4 px-5 py-2 bg-sky-200 hover:bg-sky-400 rounded-full font-semibold tracking-wide transition duration-700 ease-in-out ${
            storesid === stoora.id  ? 'bg-sky-400' : 'bg-sky-200 hover:bg-sky-400'
        }`}
        onClick={() => {
            loadStoreProducts(stoora.id);
            
          }}
        >
                            <div className="img_box relative   w-8 h-8 mr-3" >
                                
                          <img
                                  src={`${baseImageUrl}${stoora.logo}`}
                                  alt={stoora.name}
                                //  onClick={() => navigate(`/buy/${stoora.id}`)}
                                 className="w-full h-full object-contain cursor-pointer rounded-full "
                             />
                             
                             
                             
                             </div>   
                            {stoora.name}
                         </li>     
                                          
                        </>

                         ))}
                                {/* <li className='cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full' onClick={() => filterProductByStore("Daraz")}> Daraz </li>
                                <li className='cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full' onClick={() => filterProductByStore("Levis")}> Levis </li>
                                <li className='cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full' onClick={() => filterProductByStore("Abbasi")}> Abbasi  </li>
                                <li className='cursor-pointer mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full' onClick={() => filterProductByStore("Outfitter")}> Outfitter </li> */}
                            </ul>



                        </div>
                    </div>


                    <div className="product ">
                        <h2 className="text-2xl font-semibold">Top Products</h2>
                        <div className="container grid grid-cols-1 px-14 md:px-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-10">
                        {products.map((object) => (
                                <div className="box group duration-500 hover:border-blue-500  bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" 
                                key={object.id}>
                                    <div className="img_box relative h-[50%]">
                                        <img
                                            src={`${baseImageUrl}${object.thumb_image}`}
                                            alt={object.Title}
                                            onClick={() => navigate(`/buy/${object.id}`)}
                                            className="w-full h-60 object-cover cursor-pointer"
                                        />
                                        <div className=" icon durattion-500 list-none translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                           
                                            <li onClick={() => view(object)} className=" bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-500" bg-gray-500 list-none text-white px-2 py-1 rounded>
                                                <BsEye className="w-6 h-6" />
                                            </li>
                                           =

                                        </div>
                                    </div>
                                    <div className="detail h-[38%] p-4 overflow-hidden">
                                    <div className='flex justify-between'>
                                        <p className="   text-lg py-2 font-bold text-gray-800 overflow-hidden overflow-ellipsis line-clamp-2 cursor-pointer hover:underline ">{object.short_name}  </p>
                                        
                                        <li onClick={() => addtowishlist(object)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
    <div
        className="w-6 h-6 flex items-center justify-center rounded-full overflow-hidden"
        onClick={() => changeColor(object.id)}
        
    >
        <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 21.638l-1.428-1.286C5.169 16.088 2 12.35 2 8.5 2 5.408 4.308 3 7.5 3c1.902 0 3.616 1.047 4.5 2.714C12.884 4.047 14.598 3 16.5 3 19.692 3 22 5.408 22 8.5c0 3.85-3.169 7.588-8.572 12.852L12 21.638z"
                fill={toggleHeart[object.id] ? 'red' : 'none'}
            />
        </svg>
    </div>
</li>
                                </div>
                                        <h3
                                            onClick={() => navigate(`/buy/${object.id}`)}
                                            className="text-sm text-gray-400 overflow-hidden overflow-ellipsis line-clamp-2"
                                        >
                                            {object.name}
                                        </h3>
                                        <StarRating rating={object.Rating} />
                                        <h4 className="text-xl py-2  font-semibold text-blue-500">${object.price}</h4>
                                    </div>
                                    <div className='w-[60%] my-2 mx-auto  flex flex-center z-[10]'> 
                                    <a href={object.url} target="_blank" class="block w-[100%] bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-4 mx-auto rounded-full text-center" role="button">Buy Now</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* {products.length > visibleItems && ( */}
                        <div class="bottom-25 py-3 md:py-7 flex justify-end pr-[3%]" >           
                        <button
        onClick={loadMoreProducts}
        className="bg-blue-900 hover:bg-primary1 text-white font-bold py-2 px-4 rounded relative transition-all duration-300"
        style={{  overflow: 'hidden' }}
    >
        <span className= "px-2 ,">Show More
        {loading && (
            <FadeLoader
                color="rgba(54, 215, 183, 1)"
                height={4}
                margin={-10}
                radius={-2}
                width={4}
                // speedMultiplier={1}
                className=" absolute ml-6 py-1 "
                // style={{ zIndex: 1 }}
            />
        )}
        </span>
    </button>
                        </div> 
                            {/* )} */}
                    </div>
                </div >
            </div >
        </>
    );
};

export default Store;
