import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Rout from './Routes/Rout';
import productDetail from './Routes/productDetail';
import PacmanLoader from "react-spinners/PacmanLoader";
import { DotLoader, FadeLoader } from 'react-spinners';
import { fetchproductss } from './api/api';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    // Clear the timeout if component unmounts before 5 seconds
    return () => clearTimeout(timer);
  }, []);

  // add to Wishlist
  const [wishlist, setWishlist] = useState([]);

  // Product Detail
  const [close, setClose] = useState(false);
  const [detail, setDetail] = useState([]);

  // Load wishlist data from localStorage on component mount
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  // Save wishlist data to localStorage whenever wishlist changes
  useEffect(() => {
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
  }, [wishlist]);

  // Filter product
  const [product, setProduct] = useState(productDetail);
  const searchBtn = (product) => {
    const change = productDetail.filter((x) => x.Cat.toLowerCase() === product.toLowerCase());
    setProduct(change);
  };

  // Product detail
  const view = (product) => {
    setDetail([{ ...product }]);
    setClose(true);
  };

  // Add to wishlist
  const [toggleHeart, setToggleHeart] = useState(false);

const addtowishlist = (product) => {
    const exist = wishlist.find((x) => x.id === product.id);
    if (exist) {
        // Product exists in wishlist, remove it
        const updatedWishlist = wishlist.filter((x) => x.id !== product.id);
        setWishlist(updatedWishlist);
        localStorage.setItem("CartData", JSON.stringify(updatedWishlist));
        // alert("Product is removed from wishlist");
        setToggleHeart(false); // Set toggleHeart to false since product is removed
    } else {
        // Product doesn't exist in wishlist, add it
        const updatedProduct = { ...product, qty: 1, addedToWishlist: true };
        setWishlist([...wishlist, updatedProduct]);
        localStorage.setItem("CartData", JSON.stringify([...wishlist, updatedProduct]));
        // alert("Product is added to wishlist");
        setToggleHeart(true); // Change the toggleHeart state to true after adding to wishlist
    }
};
const [products, setProducts] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const data = await fetchproductss();
      setProducts(data);
    } catch (error) {
      // Handle the error if needed
    }
  };

  fetchData();
}, []);



  return (
    <>
      {loading && (
       <div className='loader-overlay flex justify-center items-center fixed top-0 left-0 w-full h-full bg-sky-50'>
          <div className='loader'>
          <DotLoader  color="black"size={100} aria-label="Loading Spinner" />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <Navbar searchBtn={searchBtn} wishlistCount={wishlist.length}    />
          <Rout product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} wishlist={wishlist} setWishlist={setWishlist} addtowishlist={addtowishlist} />
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
