/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';
import { AiOutlineHeart, AiOutlineCloseCircle } from 'react-icons/ai';
import { useAuth0 } from "@auth0/auth0-react";
import StarRating from '../Components/StarRating';
import productDetail from './productDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { FiHeart } from 'react-icons/fi';
import axios from 'axios';
import { baseImageUrl, fetchCategoryAPI, fetchFilteredProducts, fetchFilteredProductsCategory, fetchProducts, fetchproductss } from '../api/api';
import Navbar from '../Components/Navbar';

const Electronics = ({idfiltering, product, setProduct, detail, view, close, setClose, addtowishlist, }) => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { categoryId, subCategoryId, childCategoryId } = useParams();
    const [visibleProducts, setVisibleProducts] = useState(4)
    const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);
   


//    setProduct(category);**********************************Category API****************************************************
const [category, setCategory] = useState([]);
useEffect(() => {
  const fetchData = async () => {
    try {
      const data = await fetchCategoryAPI();
      setCategory(data);
    } catch (error) {
      // Handle the error if needed
    }
  };

  fetchData();
}, []);
console.log("Category from API",category);


//    setProduct(category);**********************************Product API****************************************************

useEffect(() => {
  console.log("categoryId:", categoryId);
  console.log("subCategoryId:", subCategoryId);
  console.log("childCategoryId:", childCategoryId);
  
  const fetchData = async () => {
      try {
          const newProducts = await fetchFilteredProductsCategory(categoryId, subCategoryId, childCategoryId);
          setProducts(newProducts);
            console.log("PRODUCTSS",newProducts)
      } catch (error) {
          console.error('Error fetching products:', error);
      }
  };

  fetchData();
}, [categoryId, subCategoryId, childCategoryId]);



const handleCategoryClick = (categoryId) => {
  navigate(`/category/${categoryId}`);
};

const handleSubCategoryClick = (categoryId, subCategoryId) => {
  navigate(`/category/${categoryId}/${subCategoryId}`);
};

const handleChildCategoryClick = (categoryId, subCategoryId, childCategoryId) => {
  navigate(`/category/${categoryId}/${subCategoryId}/${childCategoryId}`);
};




// useEffect(() => {
//   setFilteredProducts(products);
// }, [products]);


// const handleCategoryClick = (category) => {
//   navigate(`/category/${category.id}`);
// };

// const handleSubCategoryClick = (subcategory) => {
//   navigate(`/category/${categoryID}/${subcategory.id}`);
// };

// const handleChildCategoryClick = (childcategory) => {
//   navigate(`/category/${categoryID}/${subCategoryID}/${childcategory.id}`);
// };



// const loadCategoryProducts = async (categoryId) => {
//   try {
//       console.log("products on store item click:", categoryId)
//       const newProducts = await fetchFilteredProducts(0, visibleProducts, categoryId);
//       setProducts(newProducts);
//       setVisibleProducts(3); // Reset visibleProducts to initial value when loading products for a new store
     
//   } catch (error) {
//       console.error('Error fetching more products:', error);
//   }
// };

// const [loading, setLoading] = useState(false); // State for loader
// useEffect(()=>{
//     setLoading(false)
//     setTimeout(()=>{
//         setLoading(false)
//     },5000)
// },[])

// const loadMoreProducts = async () => {
//   try {
//     setLoading(true); 
//     const newProducts = await fetchFilteredProducts(visibleProducts + 4, visibleProducts + 4);
//     setProducts((prevProducts) => [...prevProducts, ...newProducts]);
//     setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
//   } catch (error) {
//     console.error('Error fetching more products:', error);
//   }finally {
//     setLoading(false); // Stop the loader
// }
// };
 
    return (
      
        <>
            {close ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-25 bg-black">
                    <div className="container mx-auto bg-white rounded-lg p-8 shadow-lg relative mb-b">
                        <button
                            onClick={() => setClose(false)}
                            className="absolute top-0 right-0 mt-2 mr-2 text-2xl text-gray-600 cursor-pointer"
                        >
                            <AiOutlineCloseCircle />
                        </button>
                        {detail.map((productos) => (
                            <div className="flex space-x-4" key={productos.id}>
                                <div className="w-1/2 ">
                                    <img  
                                     src={`${baseImageUrl}${productos.thumb_image}`}
                                     alt={productos.name} 
                                     className="w-64 h-64 object-contain mx-auto" />
                                </div>
                                <div className="w-1/2">
                                    <h4 className="text-blue-900 text-lg">{productos.short_name}</h4>
                                    <h2 className="text-2xl text-blue-900 font-semibold">{productos.name}</h2>
                                    {/* <StarRating rating={productos.Rating} /> */}
                                    <p className="text-gray-600">
                                        {productos.short_description}                                    </p>
                                    <h3 className="text-blue-900 text-xl font-semibold">${productos.price}</h3>
                                    <button onClick={() => addtowishlist(productos)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                                       <div className='flex flex-row items-center justify-between'>
                                        
                                        <p>Add To wishlist </p><AiOutlineHeart className='ml-1'/>
                                        </div> 
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}




            <div className='products'>
                <div className="flex items-center justify-center mb-4">
                    <h2 className="text-2xl font-semibold text-center">Products</h2>
                    {/* <p className="text-gray-500">Home . products</p> */}
                </div>
                <div className='container'>
       {/* <div className='filter'>
                        <div className='categories'>
                            <h3 className="mb-2 text-lg font-semibold">Categories</h3>
                            <ul className="flex flex-wrap justify-center">
                            <li
            className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
              filteredCategory === null && 'bg-gray-500 text-white'
            }`}
            onClick={AllProducts}
          >
            All Products
          </li>
          <li
            className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
              filteredCategory === "electronics" && 'bg-gray-500 text-white'
            }`}
            onClick={() => filterProduct("electronics")}
          >
            Electronics
          </li>
             <li className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
              filteredCategory === "whit-bag" && 'bg-gray-500 text-white'
            }`} 
            onClick={() => filterProduct("whit-bag")}>
                White bag
                </li>
            <li className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
              filteredCategory === "jewelery" && 'bg-gray-500 text-white'
            }`} 
            onClick={() => filterProduct("jewelery")}>
                jewelery</li>

            <li className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
               filteredCategory === "men's clothing" && 'bg-gray-500 text-white'
            }`}
            onClick={() => filterProduct("men's clothing")}
          >
            Men Clothings
            </li>
            <li className={`cursor-pointer mr-4 mb-2 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-full ${
              filteredCategory === "Dresses" && 'bg-gray-500 text-white'
            }`} 
            onClick={() => filterProduct("Dresses")}>
                Dresses</li>
            </ul>

                        </div>
                    </div> */}   


         <div className="product py-5 ">
             <h2 className="text-2xl font-semibold">Top Products</h2>
              {/* {Object.values(category).map((cat)=>(
                <div className="" key={cat.category_id}>
                  
                  </div>
              ))} */}
             <div className="container py-10 grid grid-cols-1 px-6 md:px-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
             
             {products.map((productos) => (
                     <div className="box group duration-500 hover:border-blue-500  bg-white border rounded-[1rem] md:rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={productos.id}>
                         <div className="img_box relative">
                             <img
                                  src={`${baseImageUrl}${productos.thumb_image}`}
                                  alt={productos.name}
                                 onClick={() => navigate(`/buy/${productos.id}`)}
                                 className="w-full h-60 object-contain cursor-pointer"
                             />
                             <div className=" icon durattion-500 list-none translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                
                                 <li onClick={() => view(productos)} className=" bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-500" bg-gray-500 list-none text-white px-2 py-1 rounded>
                                     <BsEye className="w-6 h-6" />
                                 </li>
                             </div>
                         </div>
                         <div className="detail h-[36%] p-4 overflow-hidden">
                         <div className='flex justify-between'>
                             <p className="text-lg py-2 font-bold text-gray-800 overflow-hidden overflow-ellipsis line-clamp-1 cursor-pointer hover:underline "> {productos.short_name}</p>
                             
                                 <li onClick={() => addtowishlist(productos)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
                      <FiHeart className={ 'w-6 h-6 red'}  />
                     </li>
                     </div>
                             <h3
                                 onClick={() => navigate(`/buy/${productos.id}`)}
                                 className="  text-sm text-gray-400 overflow-hidden overflow-ellipsis line-clamp-2"
                             >
                                 {productos.name}
                             </h3>
                             <h4 className="text-xl py-2  font-semibold text-blue-500">${productos.price}</h4>
                             <del className="text-black">${productos.offer_price}</del>
                         </div>
                         <div className='w-[60%] my-2 mx-auto  flex flex-center z-[10]'> 
                         <a href={productos.url} target="_blank" class="block w-[100%] bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-4 mx-auto rounded-full text-center" role="button">Buy Now</a>
                 </div>
                     </div>
                 ))}
             </div>
             {/* <div className="container grid grid-cols-1 px-14 md:px-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
             {productDetail.slice(0, visibleItems).map((object) => (
                     <div className="box group duration-500 hover:border-blue-500  bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={object.id}>
                         <div className="img_box relative">
                             <img
                                 src={object.Img}
                                 alt={object.Title}
                                 onClick={() => navigate(`/buy/${object.id}`)}
                                 className="w-full h-60 object-cover cursor-pointer"
                             />
                             <div className=" icon durattion-500 list-none translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                
                                 <li onClick={() => view(object)} className=" bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-500" bg-gray-500 list-none text-white px-2 py-1 rounded>
                                     <BsEye className="w-6 h-6" />
                                 </li>
                             </div>
                         </div>
                         <div className="detail h-[38%] p-4 overflow-hidden">
                         <div className='flex justify-between'>
                             <p className="text-sm text-gray-400">{object.Cat}</p>
                             
                                 <li onClick={() => addtowishlist(object)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
                      <FiHeart className={ 'w-6 h-6 red'}  />
                     </li>
                     </div>
                             <h3
                                 onClick={() => navigate(`/buy/${object.id}`)}
                                 className="text-lg py-2 font-bold text-gray-800 cursor-pointer hover:underline"
                             >
                                 {object.Title}
                             </h3>
                             <StarRating rating={object.Rating} />
                             <h4 className="text-xl py-2  font-semibold text-blue-500">${object.Price}</h4>
                         </div>
                         <div className='w-[60%] my-2 mx-auto  flex flex-center z-[10]'> 
                        <button className="w-[100%] bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-auto rounded-full">Buy Now </button>
                 </div>
                     </div>
                 ))}
             </div> */}
             {/* {product.length > visibleItems && ( */}
             {/* <div class="bottom-25 py-3 md:py-7 flex justify-end pr-[3%]" >           
              <button  onClick={loadMoreProducts} class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Show More</button>  
                        </div> */}
             {/* )} */}
                    </div>
                </div >
            </div >
        </>
    );
};

export default Electronics;
