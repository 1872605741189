import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Product from './Product'
import WishList from './WishList'
import Buy from './Buy'
import Contact from './Contact'
import Login from "./Login"
import SignupForm from './signUp'
import CategoryProducts from './CategoryProducts';
import Electronics from './Electronics'
import Store from './Store'
import SliderPage from '../Components/Slider1'
import Products from './Products'
import PrivacyPolicy from '../Components/help/PrivacyPolicy'
import TermsAndConditions from '../Components/help/Terms&conditions'
import Faq from '../Components/help/Faqs.js'
import AboutUs from '../Components/help/about.js'
import Category from './Category.js'


const Rout = ({ product, setProduct, detail, view, close, setClose, cart, setCart, addtocart, wishlist, setWishlist, addtowishlist }) => {
    return (
        <>
            <Routes>

                <Route path='/' element={<Home detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/Products'  element={<Products product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose}  addtowishlist={addtowishlist} />} />
                <Route path='/Store' element={<Store category="store"product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                {/* <Route path='/product' element={<Product product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/Men' element={<CategoryProducts category="Men" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/Women' element={<CategoryProducts category="Women"  product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/casual' element={<CategoryProducts category="Casual"  product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/motherchild' element={<CategoryProducts category="MotherChild" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/shoes' element={<CategoryProducts category="Shoes" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/bags' element={<CategoryProducts category="Bags" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} /> */} 
                <Route path='/Electronics/:categoryId/:subCategoryId' element={<Electronics  product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                {/* <Route path='/dresses' element={<Electronics idfiltering="10" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />}/> */}
                {/* <Route path='/dresses' element={<Electronics idfiltering="8" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />}  
                <Route path='/bags' element={<Electronics idfiltering="10" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />
                <Route path='/shoes' element={<Electronics idfiltering="12" product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />  */}
                {/* <Route path='/cosmetics' element={<CategoryProducts category="Cosmetics"product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose} addtocart={addtocart} addtowishlist={addtowishlist} />} />*/}
                 
                <Route path='/Category/:categoryId/:subCategoryId/:childCategoryId'  element={<Category product={product} setProduct={setProduct} detail={detail} view={view} close={close} setClose={setClose}  addtowishlist={addtowishlist} />} />
              
                
                <Route path='/wishlist' element={<WishList wishlist={wishlist} setWishlist={setWishlist} />} />
                <Route path='/contact' element={<Contact />} />
                <Route path="login" element={<Login />} />
                <Route path='/signUp' element={<SignupForm />} />
                <Route path='/buy/:id' element={<Buy view={view} detail={detail} selectedProduct={setProduct} onClose={onclose} />} />
                <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>} />
                <Route path="/terms&condtions" element={<TermsAndConditions/>} />
                <Route path='/FAQs'element={<Faq/>} />
                <Route path='/about'element={<AboutUs />} />
                
            </Routes>                              
        </>
    )
}

export default Rout