/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { baseImageUrl, fetchHomeProducts, fetchProducts, fetchproductss } from '../api/api';
import StarRating from '../Components/StarRating';

const Buy = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchproductss ();
        setProducts(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching products: {error.message}</div>;
  }

  const filteredProduct = products.find((item) => item.id == id);

  if (!filteredProduct) {
    return <div>Product not found</div>;
  }

  const selectedCategory = filteredProduct.category_id;

  const suggestedProducts = products.filter(
    (item) => item.category_id === selectedCategory && item.id !== id
  );

  return (
    <div>
      <div className="container">
        <div className="container p-10 hidden md:flex flex-row">
          <div id={`product_${filteredProduct.id}`} className="w-1/2 flex justify-center items-center object-contain border border-black">
            <img
              src={`${baseImageUrl}${filteredProduct.thumb_image}`}
              alt={filteredProduct.name}
              className="w-full h-80 object-contain cursor-pointer"
            />
          </div>
          <div className="w-1/2 p-6 border border-black">
            <h2 className="text-3xl font-semibold">{filteredProduct.name}</h2>
            <h4 className="category text-xl">{filteredProduct.short_description}</h4>
            <p className="mt-4" dangerouslySetInnerHTML={{ __html: filteredProduct.long_description }} />
            <h3 className="text-2xl mt-4">${filteredProduct.price}</h3>
            <div className='mt-4'>
              <a href={filteredProduct.url} target="_blank" className=" bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-5 mt-5 rounded-md " role="button">Buy Now</a>
            </div>
          </div>
        </div>

        <div className="md:hidden">
          <div className="border border-black ">
            <img
              src={`${baseImageUrl}${filteredProduct.thumb_image}`}
              alt={filteredProduct.name}
              className="w-full h-80 object-contain cursor-pointer"
            />
          </div>
          <div className="p-6 border border-black">
            <h2 className="text-3xl font-semibold">{filteredProduct.name}</h2>
            <h4 className="category text-xl">{filteredProduct.name}</h4>
            <p className="mt-4">{filteredProduct.short_description}</p> 
            <h3 className="text-2xl mt-4">${filteredProduct.price}</h3>
            <div className="mt-4">
              <a href={filteredProduct.url} target="_blank" className="bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-5 mt-5 rounded-md" role="button">Buy Now</a>
            </div>
          </div>
        </div>

        {/* <div className='bg-gray-200 p-4 mt-4 border border-gray-300 shadow-md flex justify-between'>
          <div className="w-1/3"> 
            <h3 className="text-xl font-semibold">Specifications</h3>
          </div>
        </div> */}

        <div className="mt-4">
          <h3 className="text-3xl text-blue-900 mb-4">Suggested Products</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {suggestedProducts.slice(0, 3).map((product) => (
              <div className="mb-4" key={product.id}>
                <div
                  id={`suggestedProduct_${product.id}`}
                  className="border border-gray-300 rounded-lg overflow-hidden hover:scale-105 hover:shadow-md cursor-pointer"
                >
                  <div className="relative overflow-hidden">
                    <img
                      src={`${baseImageUrl}${product.thumb_image}`}
                      alt={product.name}
                      className="w-full h-60 object-contain cursor-pointer"
                      onClick={() => {
                        navigate(`/buy/${product.id}`);
                        const suggestedProductContainer = document.getElementById(`suggestedProduct_${product.id}`);
                        if (suggestedProductContainer) {
                          window.scrollTo({
                            top: suggestedProductContainer.offsetTop - 1000, 
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="p-4">
                    <p className="text-gray-600 text-sm">{product.name}</p>
                    <h3 className="text-xl mt-2">{product.name}</h3>
                    <h4 className="text-blue-900 text-lg mt-2">${product.price}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
