import React, { useState } from 'react';

const SignupForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can perform actions like sending the form data to your backend for registration
        console.log('Form submitted with data:', formData);
    };

    return (
       
        <div className="max-w-md mx-auto p-9 mt-8 mb-8   border border-gray-300">
            <h2 className="text-4xl font-extrabold text-dark-grey-900 mb-6">Sign Up</h2>
            <form  onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block mb-1" htmlFor="username">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Enter your Name"
                        value={formData.username}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border focus:bg-grey-400 rounded-2xl border-gray-300 rounded"
                    />
                </div>
                
                <div className="mb-4">
                    <label className="block mb-1" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="mail@loopple.com"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border focus:bg-grey-400 rounded-2xl border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter a password"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border focus:bg-grey-400 rounded-2xl border-gray-300 rounded"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 text-sm transition duration-300 rounded-2xl   rounded-md hover:bg-blue-600 cursor-pointer w-full "
                >
                    Sign Up
                </button>
            </form>
        </div>
    );
};

export default SignupForm;
