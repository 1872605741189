

import React from 'react';

const AboutUs = () => {
  return (
    <div className="p-4">
      <h2 className="font-bold text-2xl mb-4 text-center">About Us</h2>

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4">Who We Are</h3>
        <p>Invofy Shop is your one-stop destination for [brief description of what your shop offers]. We are passionate about [mention your mission or values].</p>
      

      
        <h3 className="font-bold text-lg mb-4">Our Mission</h3>
        <p>At Invofy Shop, our mission is to [describe your mission or purpose]. We strive to [highlight your goals and objectives].</p>
      

      
        <h3 className="font-bold text-lg mb-4">Why Choose Us</h3>
        <p>We differentiate ourselves by [mention unique features, quality, or services that set you apart]. When you choose Invofy Shop, you are choosing [mention benefits for the customers].</p>
     

      {/* ... Additional sections for team, history, etc. */}

      
        <h3 className="font-bold text-lg mb-4">Contact Us</h3>
        <p>If you have any questions or would like to get in touch, feel free to contact us at [contact@email.com]. We are here to assist you!</p>
      </section>
    </div>
  );
};

export default AboutUs;
