// api.js
import axios from 'axios';


export const baseUrl = 'https://admin.invofy.shop/api/';
export const baseImageUrl = "https://admin.invofy.shop/storage/";


// export const fetchProducts = async () => {
//   try {
//     const response = await axios.get(baseUrl+'products');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching products:', error);
//     throw error; // Handle the error based on your project's requirements
//   }
// };

// api.js
export const fetchProducts = async (initial, final) => {
  try {
    const response = await axios.get(`${baseUrl}products?initial=${initial}&final=${final}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};


export const fetchCategoryAPI = async () => {
  try {
    const response = await axios.get(baseUrl+'categories');
    return response.data;
  } catch (error) {
    console.error('Error fetching data from the category API:', error);
    throw error; // Handle the error as needed
  }
};
export const fetchStoreAPI = async () => {
  try {
    const response = await axios.get(baseUrl+'stores');
    return response.data;
  } catch (error) {
    console.error('Error fetching data from the store API:', error);
    throw error; // Handle the error as needed
  }
};
export const fetchBannersAPI = async () => {
  try {
    const response = await axios.get(baseUrl+'banners');
    return response.data;
  } catch (error) {
    console.error('Error fetching data from the banners API:', error);
    throw error; // Handle the error as needed
  }
};
export const fetchCardsAPI = async () => {
  try {
    const response = await axios.get(baseUrl+'cards');
    return response.data;
  } catch (error) {
    console.error('Error fetching data from the cards API:', error);
    throw error; // Handle the error as needed
  }
};
export const fetchStoreProducts = async (initial, final, storeId) => {
  try {
    const response = await axios.post(`${baseUrl}productsbystores`, {
      initial,
      final,
      store_id: storeId,
    });
    return response.data;  
  } catch (error) {
    throw error;
  }
};

export const fetchHomeProducts = async (conditions,initial,final) => { // Remove initial and final parameters
  try {
    console.log("conditions", conditions);
    const response = await fetch(baseUrl + 'products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(conditions ,initial,final), // Pass conditions directly as the request body
    });

    if (!response.ok) {
      throw new Error('Failed to fetch home products');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching home products:', error);
    throw error;
  }
};

export const fetchproductss = async () => {
  try {
    const response = await axios.get(baseUrl+'products');
    return response.data;
  } catch (error) {
    console.error('Error fetching data from the cards API:', error);
    throw error; // Handle the error as needed
  }
};

export const fetchFilteredProductsCategory = async (initial, final, categoryId) => {
  try {
    const response = await axios.post(`${baseUrl}products`, {
      initial,
      final,
      category_id: categoryId,
    });
    return response.data;  
  } catch (error) {
    throw error;
  }
};
export const fetchFilteredProductsSubCategory = async (initial, final, categoryId , subcategoryId) => {
  try {
    const response = await axios.post(`${baseUrl}products`, {
      initial,
      final,
      category_id: categoryId,
      sub_category_id: subcategoryId,
    });
    return response.data;  
  } catch (error) {
    throw error;
  }
};
export const fetchFilteredProductsChildCategory = async (initial, final, categoryId , subcategoryId,childcategoryId) => {
  try {
    const response = await axios.post(`${baseUrl}products`, {
      initial,
      final,
      category_id: categoryId,
      sub_category_id: subcategoryId,
      child_category_id: childcategoryId
    });
    return response.data;  
  } catch (error) {
    throw error;
  }
};