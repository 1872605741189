/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FaTruckMoving } from "react-icons/fa";
import { AiOutlineHeart, AiOutlineMenu } from "react-icons/ai";
import { BsArrowRight, BsBagCheck } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { CiLogin } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { MdArrowDropDown, MdSearch } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";
import { fetchCategoryAPI, fetchSubCategoryAPI, baseUrl, baseImageUrl} from "../api/api";
import { ImSearch } from "react-icons/im";


const Navbar = ({ wishlistCount, handleCategoryClick, handleSubCategoryClick, handleChildCategoryClick }) => {
  
    const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [input, setInput] = useState([]);
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tab, setTab] = useState("category");
  console.log(wishlistCount);
  const [category, setCategory] = useState([]);
  //    setProduct(category);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCategoryAPI();
        setCategory(data);
      } catch (error) {
        // Handle the error if needed
      }
    };

    fetchData();
  }, []);
  console.log("Category from api", category);

  const [open,setOpen]=useState(false);
  const [openCategories, setOpenCategories] = useState({});
  const [openSubcategories, setOpenSubcategories] = useState({});

  const toggleCategory = (categoryId) => {
      setOpenCategories((prevOpenCategories) => ({
          ...prevOpenCategories,
          [categoryId]: !prevOpenCategories[categoryId],
      }));
  };

  const toggleSubcategory = (subcategoryId) => {
      setOpenSubcategories((prevOpenSubcategories) => ({
          ...prevOpenSubcategories,
          [subcategoryId]: !prevOpenSubcategories[subcategoryId],
      }));
  };
  const searchBtn = async (value) => {
    try {
      const response = await fetch(baseUrl+'searchproducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ keywords: value }),
        
      });

      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      const data = await response.json();
      setSearchResults(data); // Assuming the API returns the filtered products
      console.log('API Response Data:', data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
   const handleChange = (value) => {
    setInput(value);
    if (value.trim() !== '') {
      searchBtn(value);
    } else {
      setSearchResults([]); // Clear results if input is empty
    }
   }

//   const handleCategoryClick = (category) => {
//   const selectedCategory = localStorage.getItem('categoryId');
  
//   console.log('selectedCategory:', selectedCategory);
//   // navigate(`/category/${category.id}`);
// };

// const handleSubCategoryClick = (subcategory) => {
//   const selectedSubCategory = localStorage.getItem('subCategoryId');
//   console.log('selectedSubCategory:', selectedSubCategory);
//   // navigate(`/category/${categoryID}/${subcategory.id}`);
// };

// const handleChildCategoryClick = (childcategory) => {
//   const selectedChildCategory = localStorage.getItem('childCategoryId');
//   console.log('selectedChildCategory:', selectedChildCategory);
//   // navigate(`/category/${categoryID}/${subCategoryID}/${childcategory.id}`);
// };

  
   

//    const handleCategoryClick = (categoryId) => {
//     // Navigate to the category route
// };

// const handleSubCategoryClick = (subCategoryId) => {
//     // Navigate to the subcategory route
// };

// const handleChildCategoryClick = (childCategoryId) => {
//     // Navigate to the child category route
// };


  return (
    <>
      {/* Desktop Navbar */}
      <div className=" hidden md:block main_header bg-white  py-7 border-b border-blue-900">
        <div className="container flex justify-between ">
          <div className="logo  md:ml-7 mx-auto  md:mx-0">
         
            <Link to="/">
              <img src="./img/logo1.png" alt="logo" style={{ width: '160px', height:'45px' }} />
            </Link>
          </div>
          <div className="search-container relative">
  <div className="search_box flex mt-1 relative ">
    <input
      type="text"
      name="keywords"
      value={input}
      placeholder="Search Your Product..."
      autoComplete="off"
      onChange={(e) => handleChange(e.target.value)}
      className="relative border border-sky-800 rounded px-4 py-2 text-black focus:outline-none"
    />
    <button
      onClick={() => searchBtn(input)}
      className="border-3 border-blue-500 focus:border-blue-500 text-white rounded px-4 py-2 ml-2 bg-primary1 hover:bg-sky-400 transition duration-300"
    >
      Search
    </button>
  </div>
  {typeof input === 'string' && input.trim() !== '' && (
  <div className="search-results absolute top-full left-0 bg-white w-full py-4 rounded z-20">
    {searchResults.map((result) => (
      <div key={result.id} className="px-3 py-1  text-sm flex justify-start  transition-colors duration-300 ease-in-out  hover:bg-gray-100">
       
          <button
      onClick={() => {  navigate(`/buy/${result.id}`); setInput("");} }
      className="link hover:text-sky-700 "
      >
      {result.name}
    </button>
          
      </div>
    ))}
  </div>
)}



</div>

                                            
                                         
    
          <div className="mt-3">
            <Link to='/about' className=" hover:text-sky-500 mr-5">
              {" "}
              About 
            </Link>
            <Link to="/contact" className="hover:text-sky-500 mr-5">
              {" "}
              Contact Us
            </Link>
            <Link to= '/FAQs' className="hover:text-sky-500"> Help and Support</Link>
          </div>
          <div className="icon flex items-center">
            {isAuthenticated && (
              <div className="account flex items-center mr-8">
                <div className="user_icon text-black text-2xl">
                  <AiOutlineUser />
                </div>
                <p className="text-black">Hello, {user.name}</p>
              </div>
            )}
            <div className="second_icon flex items-center justify-between">
              <div className="flex">
                {" "}
                <Link
                  to="/wishlist"
                  className="links relative text-bg-primary1 hover:text-blue-700 mr-4 "
                >
                  <AiOutlineHeart className="h-9 w-7" />
                  {wishlistCount > 0 && (
                    <span className="wishlist-count absolute -top-2 -right-3 bg-blue-500  rounded-full px-2  text-white"> 
                      {wishlistCount}
                    </span>
                  )}
                </Link>
                {/* <Link to="/cart" className='links text-blue-500 hover:text-blue-700 mr-2'>
                                <BsBagCheck />
                            </Link> */}
                <Link
                  to={baseUrl}
                  target="blank"
                  className="login-btn bg-primary1 text-white rounded px-4 py-2 hover:bg-sky-400 mr-3  border-blue-500 transition duration-300"
                >
                  Login
                </Link>
              </div>
              <div className="become-seller-btn flex rounded-full overflow-hidden  mr-4 bg-primary1 text-white hover:bg-sky-400">
                <div className="  w-[170px] h-[40px] flex justify-center items-center hover:bg-black-500 cursor-pointer ">
                  <div className="flex space-x-2 items-center">
                    <a
                      href= {baseUrl.replace("/api/", "/")+"register"}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }} rel="noreferrer"
                    >
                      
                      <span className="text-[15px] font-600 ml-7px">
                        Become Vendor
                      </span>
                      <BsArrowRight
                        className=""
                        style={{ marginLeft: "4px", marginTop: "1px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile Navbar */}
      <div className="md:hidden  main_header pr-4 flex bg-white  border-b border-blue-900">
        <div className="container flex items-center py-8">
          <div className="menu pl-2">
            <AiOutlineMenu
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="w-8 h-8"
            />
          </div>

          <div className="logo   md:ml-7 mx-auto md:mx-0">
            <Link to="/">
            <img src="./img/logo1.png" alt="logo" style={{ width: '120px', height:'30px' }} />
            </Link>
          </div>
          <div className=" hidden search_box border-sky-800   ">
          <input
      type="text"
      name="keywords"
      value={input}
      placeholder="Search Your Product..."
      autoComplete="off"
      onChange={(e) => handleChange(e.target.value)}
      className="relative border border-sky-800 rounded px-4 py-2 text-black focus:outline-none"
    />
    <button
      onClick={() => searchBtn(input)}
      className="border-3 border-blue-500 focus:border-blue-500 text-white rounded px-4 py-2 ml-2 bg-primary1 hover:bg-sky-400 transition duration-300"
    >
              Search
            </button>
          </div>
        {typeof input === 'string' && input.trim() !== '' && (
  <div className="search-results absolute top-full left-0 bg-white w-full py-4 rounded z-20">
    {searchResults.map((result) => (
      <div key={result.id} className="px-3 py-1  text-sm flex justify-start  transition-colors duration-300 ease-in-out  hover:bg-gray-100">
       
          <button
      onClick={() => navigate(`/buy/${result.id}`) }
      className="link hover:text-sky-700 "
      >
      {result.name}
    </button>
          
      </div>
    ))}
  </div>
)}
          <div className="icon flex items-center">
            {isAuthenticated && (
              <div className="account flex items-center mr-8">
                <div className="user_icon text-black text-2xl">
                  <AiOutlineUser />
                </div>
                <p className="text-black">Hello, {user.name}</p>
              </div>
            )}
            <div className="second_icon flex items-center">
              {/* <Link to="/wishlist" className='links text-blue-500 hover:text-blue-700 mr-2'>
                                <AiOutlineHeart />
                            </Link> */}
              <Link
                to="/wishlist"
                className="links relative  text-bg-primary1 hover:text-sky-300 mr-4 "
              >
                <AiOutlineHeart className="h-7 w-7" />
                {wishlistCount > 0 && (
                  <span className="wishlist-count absolute -top-4 -right-3  rounded-full px-2 text-white">
                    {wishlistCount}
                  </span>
                )}
              </Link>
              {/* <Link to="/cart" className='links text-blue-500 hover:text-blue-700 mr-2'>
                                <BsBagCheck />
                            </Link> */}
              <Link
                to={baseUrl}
                target="blank"
                className="login-btn bg-primary1 text-white rounded px-4 py-2 hover:bg-sky-400 mr-3  border-blue-500 transition duration-300"
              >
                Login
              </Link>
            </div>
          </div>
        </div>

        {/* Navbar slider */}
        <div
          className={`w-full h-full bg-black/30  fixed  z-[10] ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          <div className="w-full h-[350%] relative z-[10] overflow-auto ">
            <div
              className={`w-[90%] h-full bg-white py-11 px-6  absolute ${
                isMenuOpen ? "translate-x-0" : "translate-x-full"
              } transform duration-100`}
            >
              <Link to="/" onClick={() => setIsMenuOpen(false)}>
              <img src="./img/logo1.png" alt="logo" style={{ width: '145px', height:'35px' }} />
              </Link>
              <div className="search_box relative flex mt-8 ml-4">
  
    <div className="relative flex  ">
      <input
        type="text"
        name="keywords"
        value={input}
        placeholder="Search Your Product..."
        autoComplete="off"
        onChange={(e) => handleChange(e.target.value)}
        className="border border-sky-800 rounded-l px-4 py-1 text-black focus:outline-none  "
      />
      <button
        onClick={() => searchBtn(input)}
        className=" border-3 border-blue-500 focus:border-blue-500 text-white rounded-r px-2 py-2  bg-primary1 hover:bg-sky-400 transition duration-300"
      >
        <ImSearch />
      </button>
    </div>

    {typeof input === 'string' && input.trim() !== '' && (
      <div className=" absolute top-full left-0 bg-sky-50   rounded z-20  " style={{width:"248px"}}>
        {searchResults.map((result) => (
          <div key={result.id} className="px-3 py-1 text-sm ">
            <button
              onClick={() => {
                navigate(`/buy/${result.id}`);
                setIsMenuOpen(false);
              }}
              className="link hover:text-sky-700"
            >
              {result.name}
            </button>
          </div>
        ))}
      </div>
    )}
  
</div>



              <div className="w-full mt-5 px-5 flex items-center space-x-3">
                <span
                  onClick={() => setTab("category")}
                  className={`text-base font-semibold  ${
                    tab === "category" ? "text-black" : "text-gray-200"
                  }`}
                >
                  Categories
                </span>
                <span className="w-[1px] h-[14px] bg-gray-500"></span>
                <span
                  onClick={() => setTab("menu")}
                  className={`text-base font-semibold ${
                    tab === "menu" ? "text-black" : "text-gray-200"
                  }`}
                >
                  Main Menu
                </span>{" "}
              </div>
              {tab === "category" ? (
                <div className="header  mt-5 w-full h-full ">
                  <div className="sub-header flex">
                    {/* <div className="child-header ">
                      <ul className=" w-full flex flex-col py-10 font-semibold text-2xl ">
                        <li className="relative">
                          <Link to="/" className=" link  hover:text-blue-700 ">
                            Home
                          </Link>
                          <div className="sub-menu w-full absolute left-0 top-[70px] z-20"></div>
                        </li>
                        {Object.values(category).map((categor) => (
                          <li key={categor.id} className="relative">
                            <Link
                              to="/electronics"
                              className="link hover:text-blue-700"
                            >
                              {categor.category_name}
                            </Link>
                            {categor.sub_categories && (
                              <div className="sub-menu z-20 px-0  pt-1  text-sm w-[200px]  top-[50px]  ">
                                <div
                                  className=" w-full bg-sky-100  border-2  border-sky-400 box-sizing: border-box display: inline-block rounded-xl  shadow-lg transition-all duration-300 ease-in-out divide-y divide-blue-300 "
                                  style={{
                                    minHeight: "195px",
                                    boxShadow:
                                      "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                                  }}
                                >
                                  {Object.values(categor.sub_categories).map(
                                    (subcategory) => (
                                      <div
                                        key={subcategory.id}
                                        className="sub-category  flex justify-between items-center px-5 h-10 cursor-pointer"
                                      >
                                        <div className="flex items-center space-x-6 ">
                                          <span className="img w-6 h-6 ">
                                            <img
                                              src={`${baseImageUrl}${subcategory.subcategory_image}`}
                                              alt={subcategory.name}
                                              className=" object-cover"
                                            />
                                          </span>
                                          <Link
                                            to={`/${subcategory.slug}`}
                                            className="link  hover:text-blue-700"
                                          >
                                            {subcategory.subcategory_name}
                                          </Link>

                                          <div className="sub-menu2 z-20 px-0 pt-1 ml-9 text-sm w-[200px]  absolute left-[-24px] top-[0]">
                                            <div
                                              className=" w-full  bg-sky-100  border-2  border-sky-400 box-sizing: border-box display: inline-block rounded-xl  shadow-lg transition-all duration-300 ease-in-out divide-y divide-blue-300"
                                              style={{
                                                minHeight: "195px",
                                                boxShadow:
                                                  "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                                              }}
                                            >
                                              {Object.values(
                                                subcategory.child_categories
                                              ).map((childCategory) => (
                                                <div
                                                  key={childCategory.id}
                                                  className="child-category relative flex justify-between items-center px-5 h-10 cursor-pointer"
                                                >
                                                  <div className="flex items-center space-x-6 ">
                                                    <span className="img w-6 h-6 ">
                                                      <img
                                                        src={`${baseImageUrl}${childCategory.childcategory_image}`}
                                                        alt={childCategory.name}
                                                        className=" object-cover"
                                                      />
                                                    </span>

                                                    <Link
                                                      to={`/${childCategory.slug}`}
                                                      className="link hover:text-blue-700"
                                                    >
                                                      {
                                                        childCategory.childcategory_name
                                                      }
                                                    </Link>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      <BsArrowRight />
                                                    </span>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <span>
                                            <BsArrowRight />
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  )}

                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div> */}

                <div className="w-[500px] bg-white text-black h-full overflow-auto block">
               
                       
                        
                         {Object.values(category).map((categor) => (
                <div key={categor.id} className="w-full h-full  hover:bg-sky-50 px-2 py-3">
                    <div  className="flex justify-between items-center px-2">
                        <span>
                                  {/* <Link
                              to="/electronics" onClick={() => setIsMenuOpen(false)}
                              className="link "
                            >
                              {categor.category_name}
                            </Link> */}
                             <button
                    onClick={() => {
                      navigate(`/Category/${categor.id}/_/_`);
                      setIsMenuOpen(false);
                    }}
                    
                    className="link hover:text-sky-700 "
                    >
                    {categor.category_name}
                  </button>
                        </span>
                        <span
                     onClick={() => toggleCategory(categor.id)}
                          className="transition-transform duration-700 transform "   
                >
                 <MdArrowDropDown
                 className={`${
                     openCategories[categor.id] ? 'rotate-180' : 'rotate-0'
                        } transition-transform duration-700 transform`}
                        />
                        </span>
                    </div>

                
                    {categor.sub_categories && (
                        <div
                        className={`${
                          openCategories[categor.id]
                            ? 'max-h-[500px] transition-max-height duration-700 ease-in-out overflow-hidden'
                            : 'max-h-0 overflow-hidden '
                        }`}
                      >
                            {Object.values(categor.sub_categories).map((subcategory) => (
                                <div key={subcategory.id} className="w-full h-full  hover:bg-sky-50 px-2 py-3">
                                    <div  className="flex justify-between items-center px-2">
                                        <span className=" flex items-center space-x-6">
                                        <span className="img w-6 h-6 ">
                                                <img
                                              src={`${baseImageUrl}${subcategory.subcategory_image}`}
                                              alt={subcategory.name}
                                              className=" object-cover"
                                            />
                                            </span>
                                            {/* <Link
                                            to={`/${subcategory.slug}`} onClick={() => setIsMenuOpen(false)}
                                            className="link "
                                          >
                                            {subcategory.subcategory_name} 
                                          </Link> */}
                                          <button
                                  onClick={() => {navigate(`/Category/${categor.id}/${subcategory.id}/_`);  setIsMenuOpen(false)}}
                                  className="link hover:text-sky-700 "
                                  >
                                  {subcategory.subcategory_name}
                                </button>
                                        </span>
                                        <span onClick={() => toggleSubcategory(subcategory.id)}><MdArrowDropDown /></span>
                                    </div>
                                    {subcategory.child_categories && (
                                        <div className={openSubcategories[subcategory.id] ? "h-auto overflow-hidden" : "h-0 overflow-hidden"}>
                                            {Object.values(subcategory.child_categories).map((childCategory) => (
                                                <div key={childCategory.id} className="w-full h-full hover:bg-sky-50  px-2 py-3">
                                                    <div className="flex justify-between items-center px-2">
                                                    <span className=" flex items-center space-x-6">
                                                             <span className="img w-6 h-6 ">  
                                                      <img
                                               src={`${baseImageUrl}${childCategory.childcategory_image}`}
                                                        alt={childCategory.name}
                                                        className=" object-cover"
                                                     />
                                          </span>    
                                                           {/* <Link
                                                      to={`/${childCategory.slug}`} onClick={() => setIsMenuOpen(false)}
                                                      className="link"
                                                    >
                                                      {
                                                        childCategory.childcategory_name
                                                      }
                                                    </Link> */}
                                                    <button
                                                      onClick={() => { navigate(`/Category/${categor.id}/${subcategory.id}/${childCategory.id}`); setIsMenuOpen(false)}}
                                                      className="link hover:text-sky-700"
                                                      >
                                                      {childCategory.childcategory_name}
                                                    </button>
                                                        </span>
                                                        <span><MdArrowDropUp /></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    


        





                  </div>
                </div>
              ) : (
                <div className="category-item mt-5 w-full">
                  <ul className="py-10 font-bold text-3xl">
                    
                  <Link to="/" onClick={() => setIsMenuOpen(false)}
                          className=" link   ">
                            Home
                          </Link>
                    <li className="my-5">
                      <Link to="/about" onClick={() => setIsMenuOpen(false)}>
                        About Us
                      </Link>
                    </li>
                    <li className="my-5">
                      <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to='/FAQs' onClick={() => setIsMenuOpen(false)}>
                        Help and Support
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
             
            </div>
          </div>
        </div>
      </div>

      <div className="header hidden md:block bg-sky-200 py-5 shadow-md ">
        <div className="sub-header flex  justify-between">
          <div className="child-header container nav flex ">
            <ul className=" container relative text-neutral-600 text-sm font-bold lg:text-lg  font-['Lato'] leading-tight  grid md:grid-cols-6 md:gap-5 md:px-[0] lg:grid-cols-6 lg:gap-5 xl:grid-cols-8 xl:gap-5 xl:py-2 2xl:grid-cols-10 2xl:py-5 2xl:px-5 overflow-visible text-left ">
              <li className="relative">
                <Link to="/" className=" link  hover:text-blue-700 ">
                  Home
                </Link>
              </li>
              <li className="relative">
                <Link to="/Store" className=" link  hover:text-blue-700 ">
                  Stores
                </Link>
              </li>
              <li className="relative">
                <Link to="/Products" className=" link  hover:text-blue-700 ">
                  Products
                </Link>
              </li>
              {Object.values(category).map((categor) => (
                <li key={categor.id} className="relative">
                  {/* <Link
                    // to={`/Electronics/${categor.id}/${categor.id}`} 
                    to={`/Category/${categor.id}/${categor.id}/${categor.id}`} 
                    className="link hover:text-blue-700"
                    // onClick={() => handleCategoryClick(categor.id)}
                    // onClick={() => navigate(`/Electronics/${9}/${10}/`)}
                  >
                    {categor.category_name}
                  </Link> */}
                   <button
                    onClick={() => navigate(`/Category/${categor.id}/_/_`) }
                    className="link hover:text-sky-700 "
                    >
                    {categor.category_name}
                  </button>
                  {categor.sub_categories && Object.values(categor.sub_categories).length > 0 && (
                    <div className="sub-menu  px-2 pt-7 pb-2 z-20 shadow-[0_0px_9px_-6px_#3b71ca] text-sm w-[200px]  absolute left-0 top-[60px]  ">
                      <div
                        className=" w-full  bg-sky-100  border-2  border-sky-400 box-sizing: border-box display: inline-block rounded-xl  shadow-lg transition-all duration-300 ease-in-out divide-y divide-blue-200 "
                        style={{
                          minHeight: "195px",
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        {Object.values(categor.sub_categories).map(
                          (subcategory) => (
                            <div
                              key={subcategory.id}
                              className="sub-category relative flex justify-between items-center hover:bg-sky-300 py-3 px-4 h-10 cursor-pointer "
                            >
                              <div className="flex items-center space-x-6   ">
                                <span className="img w-6 h-6 ">
                                  <img
                                    src={`${baseImageUrl}${subcategory.subcategory_image}`}
                                    alt={subcategory.name}
                                    className=" object-cover"
                                  />
                                </span>

                                {/* <Link
                                  to={`/category/${categor.id}/${subcategory.id}`}
                                  className="link  hover:text-blue-700 "
                                  onClick={() => handleSubCategoryClick(subcategory.id)}
                                >
                                  {subcategory.subcategory_name}
                                </Link> */}
                                <button
                                  onClick={() => navigate(`/Category/${categor.id}/${subcategory.id}/_`) }
                                  className="link hover:text-sky-700 "
                                  >
                                  {subcategory.subcategory_name}
                                </button>

                                <div className="sub-menu2 px-2 pt-4 pb-2 z-10 shadow-[0_0px_9px_-6px_#3b71ca] overflow-hidden w-[200px] absolute left-[155px] top-[0]">
                                  <div
                                    className=" w-full bg-sky-100 border-2  overflow-hidden border-sky-400  rounded-xl transition-all duration-300 ease-in-out display: inline-block divide-y divide-blue-200 "
                                    style={{
                                      minHeight: "195px",
                                      boxShadow:
                                        "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                                    }}
                                  >
                                    {Object.values(
                                      subcategory.child_categories
                                    ).map((childCategory) => (
                                      <div
                                        key={childCategory.id}
                                        className="child-category relative flex justify-between items-center hover:bg-sky-300 py-3 px-4  h-10 cursor-pointer"
                                      >
                                        <div className="flex items-center space-x-6 ">
                                          <span className="img w-6 h-6 ">
                                            <img
                                              src={`${baseImageUrl}${childCategory.childcategory_image}`}
                                              alt={childCategory.name}
                                              className=" object-cover"
                                            />
                                          </span>
                                          {/* <Link
                                            to={`/category/${categor.id}/${subcategory.id}/${childCategory.id}`}
                                            className="link hover:text-blue-700"
                                            onClick={() => handleChildCategoryClick(childCategory)}
                                          >
                                            {childCategory.childcategory_name}
                                          </Link> */}
                                          <button
                                          onClick={() => navigate(`/Category/${categor.id}/${subcategory.id}/${childCategory.id}`) }
                                          className="link hover:text-sky-700 "
                                          >
                                          {childCategory.childcategory_name}
                                        </button>
                                        </div>
                                        <div>
                                          <span>
                                            <BsArrowRight />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span>
                                  <BsArrowRight />
                                </span>
                              </div>
                            </div>
                          )
                        )}

                        {/* {Object.values(category).map((subcategor) => ( 
                                            //     <li
                                            //     key={subcategor.id}
                                            //     className="category-item transition-all duration-300 ease-in-out"
                                            //   >
                                            //     <Link
                                            //       href={{
                                            //         pathname: "/products",
                                            //         query: { category: subcategor.slug },
                                            //       }}
                                            //       passHref
                                            //     >
                                            //       <a href='/' rel="">
                                            //         <div className=" flex justify-between items-center px-5 h-10 cursor-pointer">
                                            //           <div className="flex items-center space-x-6">
                                            //             <span className="icon w-5 h-5 bg-black">
                                            //             </span>
                                            //             <span className="name text-sm font-400">
                                            //               {subcategor.subcategory_name}
                                            //             </span>
                                            //           </div>
                                            //           <div>
                                            //             <span className="icon-arrow">
                                            //             <BsArrowRight className=''/>
                                            //             </span>
                                            //           </div>
                                            //         </div>
                                            //       </a>
                                            //     </Link>
                                            //   </li>
                                           <div key={subcategor.id} className=" flex justify-center items-center px-5 h-10 cursor-pointer">
                                           <div className="flex items-center space-x-6">
                                           <span className="icon w-5 h-5 bg-black"></span>
                                           <Link to={`/${subcategor.slug}`} className='link hover:text-blue-700'>{subcategor.subcategory_name}</Link>
                                           </div>
                                           <div>
                                           <span><BsArrowRight/></span>
                                           </div>
                                           </div>
                                                
                                            ))}*/}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* <ul className="container text-neutral-600 text-sm font-bold lg:text-lg  font-['Lato'] leading-tight  grid md:grid-cols-6 md:gap-5 md:px-[0] lg:grid-cols-6 lg:gap-5 xl:grid-cols-8 xl:gap-5 xl:py-2 2xl:grid-cols-10 2xl:py-5 2xl:px-5 overflow-visible text-left ">
                            <li>
                                <Link to='/' className='  link  hover:text-blue-700 '>Home</Link>
                            </li>
                            <li>
                                <Link to='/product' className='link  hover:text-blue-700 '>Product</Link>
                            </li>
                            <li>
                                <Link to='/Store' className='link  hover:text-blue-700 '>Store</Link>
                            </li>
                            <li>
                                <Link to='/Men' className='link  hover:text-blue-700 '>Men</Link>
                            </li>
                            <li>
                                <Link to='/Women' className='link  hover:text-blue-700 '>Women</Link>
                            </li>
                            <li>
                                <Link to='/casual' className='link  hover:text-blue-700 '>Casual</Link>
                            </li>
                            <li>
                                <Link to='/Motherchild' className='link  hover:text-blue-700 '>Mother & child</Link>
                            </li>
                            <li>
                                <Link to='/shoes' className='link  hover:text-blue-700 '>Shoes</Link>
                            </li>
                            <li>
                                <Link to='/bags' className='link  hover:text-blue-700  '>Bags</Link>
                            </li>
                            <li>
                                <Link to='/electronics' className='link  hover:text-blue-700   '>Electronics
                                </Link>
                            </li>
                            <li>
                                <Link to='/cosmetics' className='link  hover:text-blue-700 '>Cosmetics</Link>
                            </li>
                            
                        </ul> 
                    </div>*/}
          {/* <div className='auth flex items-center'>
                        {isAuthenticated ? (
                            <button
                                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                                className="text-black text-2xl cursor-pointer"
                            >
                                <CiLogout />
                            </button>
                        ) : (
                            <button
                                onClick={() => loginWithRedirect()}
                                className="text-black text-2xl cursor-pointer"
                            >
                                <CiLogin />
                            </button>
                        )}
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
