

import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="p-4">
      <h2 className="font-bold text-2xl mb-4 text-center">Terms and Conditions for Invofy Shop</h2>
      <p className="text-center mb-4">Last Updated: [Date]</p>

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4 text-center">1. Acceptance of Terms</h3>
        <p className="text-center mb-4">By using Invofy Shop, you agree to comply with and be bound by these Terms and Conditions.</p>
      

     
        <h3 className="font-bold text-lg mb-4 text-center">2. Use of the Platform</h3>
        <p className="text-center mb-4">You agree to use Invofy Shop for lawful purposes and in accordance with these Terms and Conditions.</p>
      

      
        <h3 className="font-bold text-lg mb-4 text-center">3. User Account</h3>
        <p className="text-center mb-4">If you create an account with us, you are responsible for maintaining the confidentiality of your account and password.</p>
    

      {/* ... Additional sections for 4. Intellectual Property, 5. Limitation of Liability, etc. */}

     
        <h3 className="font-bold text-lg mb-4 text-center">10. Contact Information</h3>
        <p className="text-center">If you have any questions or concerns about these Terms and Conditions, please contact us at [contact@email.com].</p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
