import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform authentication logic here
        console.log('Logged in with:', email, password);
    };

    return (
        <div className="flex justify-center items-center py-9 h-4/5">
            <form className="border border-gray-300 p-6 rounded shadow-md w-96" onSubmit={handleSubmit}>
                <h2 className="text-4xl font-extrabold text-dark-grey-900 mb-6">Login</h2>
                <div className="mb-4">
                    <label htmlFor="email" className="block font-semibold mb-1 ">Email:</label>
                    <input
                        type="email"
                        id="email"
                        className="w-full px-3 py-2 border focus:bg-grey-400 rounded-2xl border-gray-300 rounded"
                        placeholder="mail@loopple.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block font-semibold mb-1">Password:</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Enter a password"
                        className="w-full px-3 py-2  focus:bg-grey-400 rounded-2xl border border-gray-300 rounded"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 w-full text-sm  transition duration-300 rounded-2xl    text-white border-none rounded py-2 px-3 mb-5  cursor-pointer hover:bg-blue-700"
                >
                    Login
                </button>
                <div className="mt-4">
                    <p>If you don't have an account, <Link to="/signup" className="text-blue-500 hover:underline">signup!</Link></p>
                </div>
            </form>
        </div>
    );
}

export default Login;
