import React, { useEffect, useState } from 'react';
import { CiLocationArrow1 } from 'react-icons/ci';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { baseImageUrl, fetchBannersAPI } from '../api/api';

const Slider = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1028 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1028, min: 640 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 640, min: 0 },
            items: 1
        }
    };

    const [banner, setBanner] = useState([]);
    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const data = await fetchBannersAPI();
                setBanner(data);
            } catch (error) {
                // Handle the error if needed
            }
        };

        fetchBannerData();
    }, []);

    console.log('banners from API', banner);

    return (
        <>
            <Carousel responsive={responsive} className="z-[1]">
                {banner.map((ban) => (
                    <div key={ban.id} className="bg-primary1 p-6 sm:w-full md:w-full lg:w-full xl:w-full z-[-1]">
                        <div className="container flex flex-col-reverse sm:flex-row justify-between">
                            <div className="my-8 ml-8">
                                <p className="md:text-6xl lg:text sm:text-2xl text-sky-200 mb-6 sm:text-left">
                                    {ban.title}
                                </p>
                                <a
                                    href={ban.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-2 py-2 md:px-4 md:py-3 lg:w-[9rem] sm:w-[6rem] text-blue-1300 bg-sky-300 rounded-full hover:bg-sky-500 hover:text-white transition duration-500 flex items-center justify-center mt-4"
                                    style={{ width: 'fit-content' }}
                                >
                                    <span className="text-base ">Shop Now</span>
                                    <CiLocationArrow1 className="text-lg mx-1 my-auto" />
                                </a>
                            </div>
                            <div className="img_box w-full sm:w-[50%] h-[250px] sm:h-full mb-4 sm:mb-0">
                                <img
                                    src={`${baseImageUrl}${ban.banner}`}
                                    alt="sliderimg"
                                    className="object-cover w-full h-full"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </>
    );
};

export default Slider;
