/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Contact = () => {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();
    const [users, setUser] = useState({
        Name: '',
        Email: '',
        Subject: '',
        Message: '',
    });
    let name, value;

    const data = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUser({ ...users, [name]: value });
    };

    const senddata = async (e) => {
        e.preventDefault();
        const { Name, Email, Subject, Message } = users;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Name,
                Email,
                Subject,
                Message,
            }),
        };

        const res = await fetch('https://e-commerce-contact-ba862-default-rtdb.firebaseio.com/Message.json', options);

        if (res) {
            alert('Your Message sent');
        } else {
            alert('An error occurred');
        }
    };

    return (
        <>
            <div className="contact bg-gray-100">
                {/* <h2 className="text-uppercase text-2xl text-blue-900 font-light">Contact Us</h2> */}
                <div className="flex items-center justify-center p-12">
                    <div className=" form bg-white p-6 rounded-lg shadow-md mx-auto w-full max-w-[550px] ">
                        <form method="POST">
                            <div className='flex justify-center'>
                            <h2 className="text-uppercase text-3xl text-blue-900 font-bold py-5">Contact Us</h2>
                            </div>
                        <div class="mb-5">
                        <label
                        for="name"
                        class="mb-3 block text-base font-medium text-[#07074D]"
                        >
                        Full Name
                        </label>
                            <input
                                type="text"
                                name="Name"
                                value={users.Name}
                                required
                                autoComplete="off"
                                onChange={data}
                                placeholder="Full Name"
                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                            </div>
                            <div class="mb-5">
                            <label
                            for="email"
                            className="mb-3 block text-base font-medium text-[#07074D]"
                            >
                            Email Address
                            </label>
                            <input
                                type="email"
                                name="Email"
                                value={users.Email}
                                placeholder="example@domain.com"
                                autoComplete="off"
                                onChange={data}
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                />
                            </div>
                            <div class="mb-5">
                                <label
                                for="subject"
                                className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                Subject
                                </label>
                            <input
                                type="text"
                                name="Subject"
                                value={users.Subject}
                                placeholder="Enter Your Subject"
                                autoComplete="off"
                                onChange={data}
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                />
                            </div>
                            <div class="mb-5">
                            <label
                            for="message"
                            class="mb-3 block text-base font-medium text-[#07074D]"
                            >
                            Message
                            </label>
                            <textarea
                                name="Message"
                                value={users.Message}
                                placeholder="Your Message"
                                autoComplete="off"
                                onChange={data}
                                className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                />
                            </div>
                            <div className='flex justify-start'>
                            {isAuthenticated ? (
                                <button
                                    type="submit"
                                    onClick={senddata}
                                    className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
                                    >
                                    Send
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    onClick={() => loginWithRedirect()}
                                    className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
                                    >
                                    Login to Send
                                </button>
                            )}
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
