/* eslint-disable no-unused-vars */
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import StarRating from '../Components/StarRating';
import { RiShoppingBag2Line, RiShoppingCart2Line } from "react-icons/ri";
import { baseImageUrl } from '../api/api';

const WishList = ({ wishlist, setWishlist }) => {
    // Increase qty
    const incqty = (product) => {
        const exist = wishlist.find((x) => x.id === product.id);
        setWishlist(wishlist.map((curElm) => (curElm.id === product.id ? { ...exist, qty: exist.qty + 1 } : curElm)));
    };

    // Decrease Qty
    const decqty = (product) => {
        const exist = wishlist.find((x) => x.id === product.id);
        setWishlist(wishlist.map((curElm) => (curElm.id === product.id ? { ...exist, qty: exist.qty - 1 } : curElm)));
    };

    // Remove wishlist product
    const removeproduct = (product) => {
        const exist = wishlist.find((x) => x.id === product.id);
        if (exist.qty > 0) {
            setWishlist(wishlist.filter((x) => x.id !== product.id));
        }
    };
    const isObjectInWishlist = (product) => {
        return wishlist.some((item) => item.id === product.id);
    };
    const navigate = useNavigate()
    
    return (
        <div className='container flex justify-center'>
            {wishlist.length === 0 && (
                <div className='emptywishlist py-5'>
                    <h2 className='empty text-2xl font-bold py-2'>Wishlist is Empty</h2>
                    <Link to='/Products'  className='emptywishlistbtn'>
                        <button id="customButton" class="bg-blue-500 mx-5 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full flex items-center space-x-2">
                          
                            <RiShoppingCart2Line className="text-xl mr-1"/>Shop Now  
                            
                            </button>
                    </Link>
                </div>
            )}
            <div className='contant'>
            <div className="container py-10 px-10 md:px-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {wishlist.map((curElm) => (
                     <div className="box group duration-500 bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={curElm.id}>
                     <div className="img_box relative">
                         <img
                             src={`${baseImageUrl}${curElm.thumb_image}`}
                             alt={curElm.name}
                             onClick={() => navigate(`/buy/${curElm.id}`)}
                             className="w-full h-60 object-contain cursor-pointer"
                         />
                     </div>
                     <div className="detail p-4">
                         <p className="text-sm text-gray-400">{curElm.category}</p>
                         <h3
                             onClick={() => navigate(`/buy/${curElm.id}`)}
                             className="text-lg py-2 font-bold text-gray-800 cursor-pointer hover:underline"
                         >
                             {curElm.name}
                         </h3>
                         {/* <StarRating rating={curElm.Rating} /> */}
                         <h4 className="text-xl py-2  font-semibold text-blue-500">${curElm.price}</h4>
                     <div className='flex'>
                     <div className='my-2 mx-auto z-[10]'>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Buy Now </button>
                     </div>
                     <div className='close my-2 ml-10'>
                          {/* <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => removeproduct(curElm)}>
                          Remove
                          </button> */}
                            <button className={`bg-${curElm.addedToWishlist ? 'red' : 'gray'}-500 hover:bg-${curElm.addedToWishlist ? 'red' : 'gray'}-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`} onClick={() => removeproduct(curElm)}>
            Remove
          </button>
                     </div>
                     </div>
                     </div>
                 </div>
                 ))}
                {/* {wishlist.map((curElm) => (
                     <div className="box group duration-500 bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" key={curElm.id}>
                     <div className="img_box relative">
                         <img
                             src={curElm.Img}
                             alt={curElm.Title}
                             onClick={() => navigate(`/buy/${curElm.id}`)}
                             className="w-full h-60 object-cover cursor-pointer"
                         />
                     </div>
                     <div className="detail p-4">
                         <p className="text-sm text-gray-400">{curElm.Cat}</p>
                         <h3
                             onClick={() => navigate(`/buy/${curElm.id}`)}
                             className="text-lg py-2 font-bold text-gray-800 cursor-pointer hover:underline"
                         >
                             {curElm.Title}
                         </h3>
                         <StarRating rating={curElm.Rating} />
                         <h4 className="text-xl py-2  font-semibold text-blue-500">${curElm.Price}</h4>
                     <div className='flex'>
                     <div className='my-2 mx-auto z-[10]'>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Buy Now </button>
                     </div>
                     <div className='close my-2 ml-10'>
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => removeproduct(curElm)}>
                          Remove
                          </button>
                     </div>
                     </div>
                     </div>
                 </div>
                 ))} */}
                 </div>
                   {/* <div className='wishlist_item' key={curElm.id}>
                        <div className='img_box'>
                            <img src={curElm.Img} alt={curElm.Title} />
                        </div>
                        <div className='detail'>
                            <div className='info'>
                                <h4 className='uppercase text-sm text-gray-600'>{curElm.Cat}</h4>
                                <h3 className='text-2xl mt-3 text-blue-900'>{curElm.Title}</h3>
                                <p className='text-lg mt-3 text-blue-900'>Price: ${curElm.Price}</p>
                                <div className='qty mt-3 flex'>
                                    <button className='incqty' onClick={() => incqty(curElm)}>
                                        +
                                    </button>
                                    <input
                                        type='text'
                                        value={curElm.qty}
                                        className='w-12 text-center text-xl bg-white'
                                    />
                                    <button className='decqty' onClick={() => decqty(curElm)}>
                                        -
                                    </button>
                                </div>
                                <h4 className='text-2xl mt-3 text-blue-900'>
                                    sub total: ${curElm.Price * curElm.qty}
                                </h4>
                            </div>
                            <div className='close mt-3 ml-10'>
                                <button onClick={() => removeproduct(curElm)}>
                                    <AiOutlineClose />
                                </button>
                            </div>
                        </div>
                     </div> 
                ))}*/}
            </div>
            {wishlist.length > 0 && (
                <>
                    
                </>
            )}
        </div>
    );
};

export default WishList;
