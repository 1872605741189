import React, { useState, useEffect } from 'react';
import { fetchProducts, baseImageUrl, fetchFilteredProductsCategory, fetchFilteredProductsChildCategory, fetchFilteredProductsSubCategory } from '../api/api';
import axios from 'axios';
import { BsEye } from 'react-icons/bs';
import { FiHeart } from 'react-icons/fi';
import StarRating from '../Components/StarRating';
import { useNavigate } from 'react-router';
import { FadeLoader } from 'react-spinners';
import {  useParams } from 'react-router-dom';


const Category = ({ product, setProduct, detail, view, close, setClose, addtowishlist }) => {
    const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(0);
  const {categoryId, subCategoryId, childCategoryId } = useParams();
  const [key, setKey] = useState(0);


  const [loading, setLoading] = useState(false); // State for loader
  useEffect(()=>{
      setLoading(false)
      setTimeout(()=>{
          setLoading(false)
      },5000)
  },[])

  
  useEffect(() => {
    console.log("categoryId:", categoryId);
    console.log("subCategoryId:", subCategoryId);
    console.log("childCategoryId:", childCategoryId);
    const fetchData = async () => {
        try {
            if (subCategoryId !== "_" && childCategoryId !== "_" ){
                const newProducts = await fetchFilteredProductsChildCategory(0, 3, categoryId, subCategoryId, childCategoryId);
                setProducts(newProducts);
                setVisibleProducts(0);
                console.log("PRODUCTSS",newProducts)
            }
            else if (subCategoryId !== "_" && childCategoryId === "_") {
                const newProducts = await fetchFilteredProductsSubCategory(0, 3, categoryId, subCategoryId);
                setProducts(newProducts);
                setVisibleProducts(0);  
                console.log("PRODUCTSS",newProducts)
            } 
            else if (subCategoryId === "_" && childCategoryId === "_") {
              const newProducts = await fetchFilteredProductsCategory(0, 3, categoryId);
            setProducts(newProducts);  
            setVisibleProducts(0);
            console.log("PRODUCTSS",newProducts)
            }
            
             
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    fetchData();
  }, [categoryId, childCategoryId, subCategoryId]);

 

  const loadMoreProducts = async () => {
    try {
      setLoading(true); 
    //   const newProducts = await fetchProducts(visibleProducts + 4, visibleProducts + 4);
    //   setProducts((prevProducts) => [...prevProducts, ...newProducts]);
    //   setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
   

      if (subCategoryId !== "_" && childCategoryId !== "_" ){

        const newProducts = await fetchFilteredProductsChildCategory(visibleProducts + 4, visibleProducts + 4, categoryId, subCategoryId, childCategoryId);
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
    }
    else if (subCategoryId !== "_" && childCategoryId === "_") {

        const newProducts = await fetchFilteredProductsSubCategory(visibleProducts + 4, visibleProducts + 4, categoryId, subCategoryId);
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 4);
    } 
    else if (subCategoryId === "_" && childCategoryId === "_") {
    
    const newProducts = await fetchFilteredProductsCategory(visibleProducts + 4, visibleProducts + 4, categoryId);
    console.log("fetchFilteredProductsCategory",categoryId,  newProducts)
    setProducts((prevProducts) => [...prevProducts, ...newProducts]);
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 2);
    
    }
    } catch (error) {
      console.error('Error fetching more products:', error);
    }finally {
      setLoading(false); // Stop the loader
  }
  };


//   document.addEventListener("DOMContentLoaded", function() {
//     const itemGrid = document.getElementById("itemGrid");
//     const showMoreBtn = document.getElementById("showMoreBtn");
//     const initialItemsToShow = 8;
//     let currentVisibleItems = initialItemsToShow;
  
    
//     Array.from(itemGrid.children).slice(initialItemsToShow).forEach(item => {
//       item.classList.add("hidden");
//     });
  
//     showMoreBtn.addEventListener("click", function() {
//       const totalItems = itemGrid.children.length;
  
//       for (let i = currentVisibleItems; i < Math.min(totalItems, currentVisibleItems + 8); i++) {
//         if (itemGrid.children[i]) {
//           itemGrid.children[i].classList.remove("hidden");
//         }
//       }
  
//       currentVisibleItems += 8;
  
//       if (currentVisibleItems >= totalItems) {
//         showMoreBtn.style.display = "none";
//       }
//     });
//   });

//   const [visibleItems, setVisibleItems] = useState(4);

// const showMoreItems = () => {
// setVisibleItems(prev => prev + 4);
// };
const  [toggleHeart, setToggleHeart] = useState(false)


const changeColor = (id) => {
    setToggleHeart((prevState) => ({
        ...prevState,
        [id]: !prevState[id] || false // If prevState[id] is undefined (first time), set it to false
    }));
};

  return (
   
    <div className="product ">
                        <h2 className="text-2xl font-semibold text-center py-4"> Products</h2>
                        <div className="container grid grid-cols-1 px-14 md:px-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-10">
                        {products.map((object) => (
                                <div className="box group duration-500 hover:border-blue-500  bg-white border rounded-[3rem] mx-3 my-3 shadow-2xl overflow-hidden hover:scale-110 hover:shadow-md" 
                                key={object.id}>
                                    <div className="img_box relative h-[50%]">
                                        <img
                                            src={`${baseImageUrl}${object.thumb_image}`}
                                            alt={object.Title}
                                            onClick={() => navigate(`/buy/${object.id}`)}
                                            className="w-full h-60 object-cover cursor-pointer"
                                        />
                                        <div className=" icon durattion-500 list-none translate-x-[-8rem] transition-transform absolute top-4 right-[-6rem] flex flex-col space-x-2 text-gray-600">
                                           
                                            <li onClick={() => view(object)} className=" bg-gray-500 list-none text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-500" bg-gray-500 list-none text-white px-2 py-1 rounded>
                                                <BsEye className="w-6 h-6" />
                                            </li>
                                           

                                        </div>
                                    </div>
                                    <div className="detail h-[38%] p-4 overflow-hidden">
                                    <div className='flex justify-between'>
                                    <p
                  className="text-lg  overflow-hidden overflow-ellipsis line-clamp-2 font-bold text-gray-800 cursor-pointer hover:underline"
                >
                  {object.short_name}
                </p>
                <li onClick={() => addtowishlist(object)} className="list-none text-black px-2 py-1 rounded cursor-pointer">
    <div
        className="w-6 h-6 flex items-center justify-center rounded-full overflow-hidden"
        onClick={() => changeColor(object.id)}
        
    >
        <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 21.638l-1.428-1.286C5.169 16.088 2 12.35 2 8.5 2 5.408 4.308 3 7.5 3c1.902 0 3.616 1.047 4.5 2.714C12.884 4.047 14.598 3 16.5 3 19.692 3 22 5.408 22 8.5c0 3.85-3.169 7.588-8.572 12.852L12 21.638z"
                fill={toggleHeart[object.id] ? 'red' : 'none'}
            />
        </svg>
    </div>
</li>





                                </div>
                                        <h3
                                            onClick={() => navigate(`/buy/${object.id}`)} 
                                            className="  text-sm text-gray-400 overflow-hidden overflow-ellipsis line-clamp-2"
                                        >
                                            {object.name}
                                        </h3>
                                        <StarRating rating={object.Rating} />
                                        <h4 className="text-xl py-2  font-semibold text-blue-500">${object.price}</h4>
                                    </div>
                                    <div className='w-[60%] my-2 mx-auto  flex flex-center z-[10]'> 
                                    <a href={object.url} target="_blank" class="block w-[100%] bg-primary1 hover:bg-sky-500 text-white font-bold py-2 px-4 mx-auto rounded-full text-center" role="button">Buy Now</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* {products.length > visibleProducts && ( */}
                        <div class="bottom-25 py-3 md:py-7 flex justify-end pr-[3%]" >           
                        <button
        onClick={loadMoreProducts}
        className="bg-blue-900 hover:bg-primary1 text-white font-bold py-2 px-4 rounded relative transition-all duration-300"
        style={{  overflow: 'hidden' }}
    >
        <span className= "px-2 ,">Show More
        {loading && (
            <FadeLoader
                color="rgba(54, 215, 183, 1)"
                height={4}
                margin={-10}
                radius={-2}
                width={4}
                // speedMultiplier={1}
                className=" absolute ml-6 py-1 "
                // style={{ zIndex: 1 }}
            />
        )}
        </span>
    </button>
                        </div>
                             {/* )}  */}
                    </div>
  );
};

export default Category;
