// PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-4">
      <h2 className="font-bold text-2xl mb-4 text-center">Privacy Policy for Invofy Shop</h2>
      <p className="text-center mb-4">Last Updated: [Date]</p>

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4 text-center">1. Information We Collect</h3>

        <article>
          <h4 className="font-bold mb-2 text-center">1.1 Personal Information</h4>
          <p>We may collect personal information that you provide voluntarily, including but not limited to:</p>
          <ul className="list-disc list-inside">
            <li>Name</li>
            <li>Email address</li>
            <li>Billing and shipping address</li>
            <li>Payment information</li>
          </ul>
        </article>

        <article>
          <h4 className="font-bold mb-2 text-center">1.2 Non-Personal Information</h4>
          <p>We may also collect non-personal information automatically when you use our platform, such as:</p>
          <ul className="list-disc list-inside">
            <li>IP address</li>
            <li>Browser type</li>
            <li>Device information</li>
            <li>Usage data</li>
          </ul>
        </article>
      </section>

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4 text-center">2. How We Use Your Information</h3>
        <p>We use the collected information for the following purposes:</p>
        <ul className="list-disc list-inside">
          <li>Process and fulfill orders</li>
          <li>Provide customer support</li>
          <li>Improve and optimize our platform</li>
          <li>Communicate updates and promotions</li>
          <li>Comply with legal requirements</li>
        </ul>
      </section>

      {/* ... Additional sections for 3. Sharing Your Information, 4. Cookies and Tracking Technologies, etc. */}

      <section className="border p-4 mb-6">
        <h3 className="font-bold text-lg mb-4 text-center">9. Contact Us</h3>
        <p className="text-center">If you have any questions or concerns about this Privacy Policy, please contact us at [contact@email.com].</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
